import React from 'react';
import intl from 'react-intl-universal';
import { withStyles } from '@material-ui/core/styles';
import { default as Table, Cell } from '../../common/table';
import Toolbar from '@material-ui/core/Toolbar';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Paper from '@material-ui/core/Paper';
import DetailedCardRow from './DetailedCardRow';
import {
  COMMENT_COLUMN,
  ATTACHMENT_COLUMN,
  OWNER_COLUMN,
  CREATED_COLUMN,
  CREATED_BY_COLUMN,
  CHANGED_COLUMN,
  CHANGED_BY_COLUMN,
} from '../../utils/ListViewUtil';
import { getCustomSorters } from '../../utils/RenderUtil';
import PropTypes from 'prop-types';
import { connectProps } from '@devexpress/dx-react-core';
import { ColumnChooserButton } from '../common/ColumnChooserButton';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import CommentIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import AttachmentIcon from '@material-ui/icons/AttachFileOutlined';

const styles = () => ({
  noData: {
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    transform: 'none',
  },
  toolbar: {
    borderTop: '1px solid rgb(191, 191, 191)',
    backgroundColor: 'white',
  },
  flexGrow: {
    flex: '1 1 auto',
  },
});

const defaultHiddenColumnNames = [
  OWNER_COLUMN,
  CREATED_COLUMN,
  CREATED_BY_COLUMN,
  CHANGED_COLUMN,
  CHANGED_BY_COLUMN,
];

const ListBoard = props => {
  const {
    classes,
    board,
    items,
    height,
    style,
    columns,
    renderCellComponent,
    editing,
    rowToInfo,
    getRowId,
    selection,
    columnOrder,
    sorter,
    defaultSorter,
    filters,
    lastActiveItem,
    width,
    readOnly,
    title,
    subscription,
    tooltips,
    onItemCreateClick,
    onExportClick,
    onFiltersChange,
    onSearch,
    onSortChange,
    onColumnOrderChange,
    onColumnVisibilityChange,
    searchTerm,
    visibleColumns,
  } = props;

  const [state, setState] = React.useState({
    filtering: filters && !!filters.length,
    filteredRows: [],
    selectedRows: [],
    hiddenColumnNames: [],
    disableExport: false,
  });

  const vtRef = React.useRef();

  const extractHiddenByDefaultColumns = React.useCallback(
    cols => {
      if (visibleColumns && visibleColumns.length > 0) {
        return cols
          .filter(column => !visibleColumns.includes(column.name))
          .map(column => column.name);
      }

      return cols
        .filter(column => defaultHiddenColumnNames.includes(column.name))
        .map(column => column.name);
    },
    [visibleColumns],
  );

  const extractHiddenColumns = React.useCallback(
    ({ board, columns }) =>
      columns.length > 1 && isWidthDown('sm', width)
        ? columns
            .filter(
              // eslint-disable-next-line react/prop-types
              column => board.field_config && board.field_config.question_field === column.name,
            )
            .map(column => column.name)
        : extractHiddenByDefaultColumns(columns),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [width, extractHiddenByDefaultColumns],
  );

  const handleItemCreateClick = () => onItemCreateClick();

  const handleExportClick = () => {
    const { hiddenColumnNames, selectedRows, filteredRows } = state;

    let itemsToExport =
      selectedRows.length > 0
        ? selectedRows
        : (filteredRows.length ? filteredRows : items).map(item => item.id);

    const columnsToExport = columns.filter(column => !hiddenColumnNames.includes(column.name));
    const includeComments = !hiddenColumnNames.includes(COMMENT_COLUMN);

    return onExportClick({
      columns: columnsToExport,
      items: itemsToExport,
      includeComments,
    });
  };

  const handleFilteringToggle = () => {
    onFiltersChange([]);
    setState(prev => ({ ...prev, filtering: !prev.filtering }));
  };

  const toolbarRoot = React.useMemo(
    () =>
      connectProps(Toolbar, () => ({
        readOnly,
        filtering: state.filtering,
        handleItemCreateClick,
        handleExportClick,
        handleFilteringToggle,
        tooltips: {
          ...tooltips,
          export: intl.get('board.views.list.tooltips.export'),
          add_filtering: intl.get('board.views.list.tooltips.add_filtering'),
          remove_filtering: intl.get('board.views.list.tooltips.remove_filtering'),
        },
        title,
        subscription,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.filtering, readOnly, title, subscription, tooltips],
  );

  React.useEffect(() => {
    const defaultHiddenColumnNames = extractHiddenColumns({
      board,
      columns,
    });

    if (lastActiveItem) {
      vtRef.current.scrollToRow(lastActiveItem);
    }

    setState(prev => ({ ...prev, hiddenColumnNames: defaultHiddenColumnNames }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    toolbarRoot.update();
  });

  const defaultColumnWidths = columns.map(column => ({
    columnName: column.name,
    width: column.name === 'commentCount' || column.name === 'childCount' ? 70 : 170,
  }));

  return (
    <Paper style={style}>
      <Table
        columns={columns || []}
        rows={items}
        virtual
        height={height}
        filtering={
          state.filtering && {
            rowConfig: {
              messages: {
                filterPlaceholder: intl.get('board.views.list.placeholders.filter'),
              },
            },
            stateConfig: {
              columnExtensions: [
                {
                  columnName: COMMENT_COLUMN,
                  filteringEnabled: false,
                },
                {
                  columnName: ATTACHMENT_COLUMN,
                  filteringEnabled: false,
                },
              ],
              onFiltersChange: onFiltersChange,
              defaultFilters: filters,
            },
          }
        }
        gridConfig={{ getRowId: getRowId }}
        selection={
          selection.enableSelection && {
            stateConfig: {
              onSelectionChange: selection =>
                setState(prev => ({ ...prev, selectedRows: selection })),
            },
            config: {
              selectByRowClick: true,
              highlightRow: true,
              showSelectionColumn: false,
            },
          }
        }
        sorting={{
          stateConfig: {
            columnExtensions: [
              {
                columnName: COMMENT_COLUMN,
                sortingEnabled: false,
              },
              {
                columnName: ATTACHMENT_COLUMN,
                sortingEnabled: false,
              },
            ],
            defaultSorting: sorter || defaultSorter,
            onSortingChange: sorting => onSortChange(sorting),
          },
          config: {
            columnExtensions: getCustomSorters(columns),
          },
        }}
        tableHeaderRowConfig={{
          messages: {
            sortingHint: intl.get('board.views.list.tooltips.sort'),
          },
          contentComponent: ({ children, column }) => (
            <TableHeaderRow.Content>
              {column.name === 'commentCount' ? (
                <CommentIcon />
              ) : column.name === 'childCount' ? (
                <AttachmentIcon />
              ) : (
                children
              )}
            </TableHeaderRow.Content>
          ),
        }}
        editing={{
          config: {
            cellComponent: ({ row }) => {
              return editing.renderLinkButtons({ row, id: board.id });
            },
            width: editing.width,
          },
        }}
        columnResizing={{
          defaultColumnWidths,
        }}
        columnReordering={{
          config: {
            customOrder: columnOrder,
            onOrderChange: order => onColumnOrderChange(order),
          },
        }}
        columnVisibility={{
          config: {
            defaultHiddenColumnNames: extractHiddenColumns({
              board,
              columns,
            }),
            onHiddenColumnNamesChange: hiddenColumnNames => {
              const visibleColumns = columns
                .map(column => column.name)
                .filter(columnName => !hiddenColumnNames.includes(columnName));
              onColumnVisibilityChange(visibleColumns);
              setState(prev => ({ ...prev, hiddenColumnNames }));
            },
          },
          columnChooserConfig: {
            messages: {
              showColumnChooser: intl.get('board.views.list.tooltips.show_hide_columns'),
            },
            toggleButtonComponent: ColumnChooserButton,
          },
        }}
        rowDetailing={{
          config: {
            contentComponent: ({ row }) => (
              <DetailedCardRow row={row} columns={columns} rowToInfo={rowToInfo} />
            ),
          },
        }}
        toolbarConfig={{
          rootComponent: toolbarRoot,
        }}
        search={{
          panelConfig: {
            messages: {
              searchPlaceholder: intl.get('board.views.list.placeholders.search'),
            },
          },
          stateConfig: {
            defaultValue: searchTerm,
            onValueChange: onSearch,
          },
        }}
        tableConfig={{
          estimatedRowHeight: 75,
          ref: vtRef,
          cellComponent: obj => <Cell>{renderCellComponent(obj)}</Cell>,
          messages: {
            noData: <div className={classes.noData}>{intl.get('common.content.empty')}</div>,
          },
        }}
      />
      {selection.enableSelection && (
        <Toolbar className={classes.toolbar}>
          {selection.helperText || ''}
          <div className={classes.flexGrow} />
          {selection.numberOfItemsMessage &&
            selection.numberOfItemsMessage({
              totalCount: items.length,
              selectedCount: state.selectedRows.length,
              filteredCount: state.filteredRows.length,
            })}
        </Toolbar>
      )}
    </Paper>
  );
};

ListBoard.propTypes = {
  classes: PropTypes.shape({
    noData: PropTypes.string,
    toolbar: PropTypes.string,
    flexGrow: PropTypes.string,
  }).isRequired,
  board: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  height: PropTypes.number,
  width: PropTypes.number.isRequired,
  style: PropTypes.shape({
    marginBottom: PropTypes.number,
  }),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  renderCellComponent: PropTypes.func.isRequired,
  title: PropTypes.node,
  editing: PropTypes.shape({
    renderLinkButtons: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  rowToInfo: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  subscription: PropTypes.node,
  tooltips: PropTypes.shape({
    create: PropTypes.string.isRequired,
  }).isRequired,
  onItemCreateClick: PropTypes.func.isRequired,
  onExportClick: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onColumnOrderChange: PropTypes.func.isRequired,
  onColumnVisibilityChange: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})),
  getRowId: PropTypes.func,
  selection: PropTypes.shape({
    enableSelection: PropTypes.bool,
    helperText: PropTypes.string,
    numberOfItemsMessage: PropTypes.func,
  }),
  sorter: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string,
      direction: PropTypes.string,
    }),
  ),
  columnOrder: PropTypes.arrayOf(PropTypes.string),
  visibleColumns: PropTypes.arrayOf(PropTypes.string),
  lastActiveItem: PropTypes.string,
  defaultSorter: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string.isRequired,
      direction: PropTypes.string,
    }),
  ),
};

ListBoard.defaultProps = {
  height: window.innerHeight - 190,
  subscription: <React.Fragment />,
  searchTerm: '',
  getRowId: row => row['id'],
  selection: {
    enableSelection: false,
    helperText: '',
    numberOfItemsMessage: ({ totalCount, selectedCount, filteredCount }) =>
      `Displaying ${filteredCount} of ${totalCount} cards. Selected: ${selectedCount}`,
  },
  defaultSorter: [],
};

export default withStyles(styles)(withWidth()(ListBoard));
