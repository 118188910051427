import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import createRoutes from './routes';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { compose } from 'recompose';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import { bindActionCreators } from 'redux';
import ReduxToastr from 'react-redux-toastr';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// Date picker
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';

import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import { isSmartTV, isMobile } from 'react-device-detect';

import { SessionExpirationWarning, SessionExpirationMessage } from './common/session';
import { actions as appActions } from './ducks/App';
import {
  actions as authActions,
  selectIsUserProfileLoading,
  selectIsUserProfileRequested,
  selectUserProfile,
} from './ducks/Auth';
import { getUserProfile } from './thunks/Auth';
import { selectShowSidebar } from './ducks/App';
import { selectSessionExpired, selectSessionExpiring } from './ducks/Auth';
import { selectActiveBoard } from './ducks/Boards';
import {
  actions as communityActions,
  selectActiveCommunity,
  selectWasRequested,
} from './ducks/Communities';
import Header from './common/HeaderContainer';
import Sidebar from './common/Sidebar';
import { getLanguage, getLocale } from './utils/LocaleUtil';
import initLocale from './initLocale';
import * as communitiesActions from './thunks/Communities';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import { initialize as initializeGoogleAnalytics, logPageView } from './store/analytics';
import ZoomDetector from './common/ZoomDetector';
import CircularProgress from '@material-ui/core/CircularProgress';
import queryString from 'query-string';

initializeGoogleAnalytics();

const muiTheme = createMuiTheme({
  overrides: {
    MuiTableRow: {
      root: {
        height: 30,
      },
      head: {
        height: 30,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#3eb1c8',
      },
    },
    MuiPickersDay: {
      '&$selected': {
        backgroundColor: '#3eb1c8',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '0.875rem',
        fontWeight: 500,
      },
    },
  },
  palette: {
    primary: {
      light: '#42719a',
      main: '#13627c',
      dark: '#063544',
      contrastText: '#fff',
    },
    secondary: {
      light: '#42719a',
      main: '#13627c',
      dark: '#063544',
      contrastText: '#fff',
    },
    error: red,
    highlight: '#FFF3E0',
    type: 'light',
  },
});

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    paddingTop: 24,
    height: 'calc(100% - 56px)',
    marginTop: 40,
  },
});

const mapStateToProps = () =>
  createStructuredSelector({
    showSidebar: selectShowSidebar(),
    communityId: selectActiveCommunity(),
    wasCommunitiesRequested: selectWasRequested(),
    activeBoard: selectActiveBoard(),
    sessionExpired: selectSessionExpired(),
    sessionExpiring: selectSessionExpiring(),
    userProfile: selectUserProfile(),
    isUserProfileLoading: selectIsUserProfileLoading(),
    isUserProfileRequested: selectIsUserProfileRequested(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...appActions,
      ...authActions,
      ...communitiesActions,
      setActiveCommunity: communityActions.setActiveCommunity,
      getUserProfile,
    },
    dispatch,
  ),
});

const App = props => {
  const {
    history,
    communityId,
    isUserProfileRequested,
    wasCommunitiesRequested,
    actions,
    classes,
    showSidebar,
    activeBoard,
    sessionExpired,
    sessionExpiring,
    isUserProfileLoading,
    userProfile,
    onResetSessionTimeoutTrackers,
    onLogout,
    location,
  } = props;

  useEffect(() => {
    const communityIdFromUrl = queryString.parse(location.search).communityId || '';
    let currentCommunityId = communityIdFromUrl || communityId;

    if (!wasCommunitiesRequested) {
      actions.fetchCommunities().then(action => {
        currentCommunityId = currentCommunityId || action.payload.communities[0].id;
        actions.setActiveCommunity({ communityId: currentCommunityId });

        if (!isUserProfileRequested) {
          actions.getUserProfile({ communityId: currentCommunityId });
        }
      });
    }

    history.listen(() => {
      logPageView();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSidebar = () => actions.showSidebar({ show: !showSidebar });

  const calculateMarginLeft = () => {
    if (window.matchMedia('(max-width: 960px)').matches) {
      return 0;
    }
    if (showSidebar) {
      return 250;
    }
    return 45;
  };

  if (isUserProfileLoading || !isUserProfileRequested) {
    return (
      <div
        style={{
          height: `${window.innerHeight}px`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    );
  }

  const marginLeft = calculateMarginLeft();
  const width = `calc(100% - ${marginLeft}px)`;
  const locale = intl.getInitOptions().currentLocale;

  if (userProfile) {
    initLocale(getLanguage(userProfile.locale));
  } else {
    initLocale();
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(locale)}>
      <MuiThemeProvider theme={muiTheme}>
        <ZoomDetector />
        <div className={classes.root}>
          <div className={classes.appFrame}>
            <Sidebar show={showSidebar} onMenuClick={handleSidebar} activeBoard={activeBoard} />
            <Header
              activeBoard={activeBoard}
              sidebarOpen={showSidebar}
              onSidebarClick={handleSidebar}
              onLogout={onLogout}
            />
            <div className={classes.content} style={{ width, marginLeft }}>
              {createRoutes()}
            </div>
            {sessionExpiring && !sessionExpired && (
              <SessionExpirationWarning
                open
                handleClose={() => {
                  onResetSessionTimeoutTrackers();
                  actions.setSessionExpiring(false);
                }}
                title={intl.get('session.expiration.warning.title')}
                message={intl.get('session.expiration.warning.message')}
                yesText={intl.get('common.yes')}
                noText={intl.get('common.no')}
              />
            )}
            {sessionExpired && (
              <SessionExpirationMessage
                open
                title={intl.get('session.expired.title')}
                message={intl.get('session.expired.message')}
                okText={intl.get('common.ok')}
              />
            )}
          </div>
          <ReduxToastr
            timeOut={1500}
            newestOnTop={true}
            preventDuplicates
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
          />
        </div>
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

App.propTypes = {
  activeBoard: PropTypes.shape({}),
  classes: PropTypes.shape({
    root: PropTypes.string,
    appFrame: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
  showSidebar: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    showSidebar: PropTypes.func,
    setSessionExpiring: PropTypes.func,
    getUserProfile: PropTypes.func,
    setActiveCommunity: PropTypes.func,
    fetchCommunities: PropTypes.func,
  }).isRequired,
  onResetSessionTimeoutTrackers: PropTypes.func.isRequired,
  communityId: PropTypes.string,
  fetchCommunities: PropTypes.func,
  userProfile: PropTypes.shape({
    locale: PropTypes.string,
  }),
  isUserProfileRequested: PropTypes.bool.isRequired,
  isUserProfileLoading: PropTypes.bool.isRequired,
  wasCommunitiesRequested: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    listen: PropTypes.func,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  sessionExpired: PropTypes.bool,
  sessionExpiring: PropTypes.bool,
  onLogout: PropTypes.func,
};

App.defaultProps = {
  activeBoard: {},
  communityId: '',
  userProfile: {},
  location: {},
  fetchCommunities: () => {},
  onLogout: () => {},
};

export default compose(
  DragDropContext(isSmartTV || isMobile ? TouchBackend : HTML5Backend),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(App));
