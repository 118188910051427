import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useAuth0 } from '../auth0';
import PropTypes from 'prop-types';

export const SessionExpirationWarning = props => {
  const { open, handleClose, title, message, yesText, noText } = props;

  const { logout, getTokenSilently } = useAuth0();

  const handleLogout = () => {
    logout();
  };

  const handleRenewSession = () => {
    getTokenSilently()
      .then(() => {
        handleClose();
      })
      .catch(() => handleLogout());
  };

  const originalTitle = document.title;
  let titleChangeInterval;
  let hidden;
  let visibilityChange;

  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  function handleVisibilityChange() {
    if (!document[hidden]) {
      clearInterval(titleChangeInterval);
      document.title = originalTitle;
    }
  }

  if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
    console.log('Does not support Page Visibility API');
  } else {
    // Handle page visibility change
    document.addEventListener(visibilityChange, handleVisibilityChange, false);
    // Page change visibilty available, and page / tab is "hidden", i.e. not active so rotate the page
    // title every 2 second to draw attention to the page / tab.
    // This will then be cleared and original title restored as soon as the page / tab receives focus.
    if (document[hidden]) {
      titleChangeInterval = setInterval(() => {
        document.title = document.title === originalTitle ? title : originalTitle;
      }, 2000);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRenewSession} color="primary" autoFocus>
          {yesText}
        </Button>
        <Button onClick={handleLogout} color="secondary">
          {noText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SessionExpirationWarning.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  yesText: PropTypes.string.isRequired,
  noText: PropTypes.string.isRequired,
};

export const SessionExpirationMessage = props => {
  const { open, title, message, okText } = props;

  const handleClose = () => window.location.reload();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SessionExpirationMessage.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  okText: PropTypes.string.isRequired,
};
