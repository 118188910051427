import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { default as Table } from '../../common/table';
import { withStyles } from '@material-ui/core/styles';
import Immutable from 'seamless-immutable';
import IconFilterOn from '@material-ui/icons/FilterList';
import IconFilterOff from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { DateTimeFormatter } from '../../common/DateFormatter';
import StopClickPropagation from '../../common/StopClickPropagation';
import { parseISO } from 'date-fns';
import { getCustomSorters } from '../../utils/RenderUtil';
import { ENVIRONMENTAL_VARIABLES } from '../../utils/Environment';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

const styles = theme => ({
  log: {
    marginTop: 20,
  },
  badge: {
    top: '-4px',
    right: '-30px',
    transform: 'scale(1)',
  },
  column: {
    flexBasis: '33.33%',
  },
  logColumn: {
    flexBasis: '100%',
  },
  noData: {
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    transform: 'none',
  },
  flexGrow: {
    flex: '1 1 auto',
  },
});

const Log = ({ classes, log, members, item }) => {
  const [filter, setFilter] = React.useState(false);

  const handleFilteringToggle = () => setFilter(!filter);

  const getAuditActionEntryMovedContent = row => {
    if (row.value.type === 'date') {
      return intl.get('audit.action.entry.moved', {
        old_value: row.value.old_value ? parseISO(row.value.old_value) : '',
        new_value: row.value.new_value ? parseISO(row.value.new_value) : '',
      });
    }
    return intl.get('audit.action.entry.moved', {
      old_value: row.value[0].old_value,
      new_value: row.value[0].new_value,
    });
  };

  const getAuditActionCommentUpdatedContent = row => {
    const oldValueLabel = intl.get('audit.action.update.comment.old');
    const newValueLabel = intl.get('audit.action.update.comment.new');
    return oldValueLabel + row.value[0].old_value + newValueLabel + row.value[0].new_value;
  };

  const getAuditActionCommentMentionedEmailSent = row => {
    return intl.get('audit.action.comment.mentioned.email.sent.value') + row.value;
  };

  const columns = [
    {
      name: 'type',
      title: intl.get('common.log.columns.action'),
      getCellValue: log => intl.get(log.type),
    },
    {
      name: 'created',
      title: intl.get('common.attachments.columns.created'),
    },
    {
      name: 'user',
      title: intl.get('common.log.columns.user'),
      getCellValue: logInfo => {
        const user = members.find(m => m.id === logInfo.user.id);
        return user ? user.name : logInfo.user.id;
      },
    },
  ];

  return (
    <ExpansionPanel className={classes.log} disabled={!item.id}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <div style={{ paddingRight: 40, display: 'flex', width: '100%' }}>
          <div className={classes.column}>
            <Badge classes={{ badge: classes.badge }} color="primary" badgeContent={log.length}>
              <Typography className={classes.heading}>{intl.get('common.log')}</Typography>
            </Badge>
          </div>
          <div className={classes.flexGrow} />
          <StopClickPropagation
            style={{
              position: 'relative',
              paddingRight: 0,
            }}>
            <Tooltip
              title={
                filter
                  ? intl.get('board.views.list.tooltips.remove_filtering')
                  : intl.get('board.views.list.tooltips.add_filtering')
              }>
              <IconButton onClick={handleFilteringToggle}>
                {filter ? <IconFilterOff /> : <IconFilterOn />}
              </IconButton>
            </Tooltip>
          </StopClickPropagation>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className={classes.logColumn}>
          <Table
            rows={Immutable(log || []).asMutable()}
            columns={columns}
            filtering={
              filter && {
                rowConfig: {
                  messages: {
                    filterPlaceholder: intl.get('board.views.list.placeholders.filter'),
                  },
                },
                // TODO: Create custom filter, so we can filter on the number of comments and attachments, instead of disabling filtering.
                stateConfig: {},
                config: {
                  columnExtensions: [
                    {
                      columnName: 'created',
                      predicate: (value, filter) => {
                        const filterValue = DateTimeFormatter({ value });
                        return filterValue.indexOf(filter.value) !== -1;
                      },
                    },
                  ],
                },
              }
            }
            sorting={{
              config: {
                columnExtensions: getCustomSorters(columns),
              },
            }}
            tableHeaderRowConfig={{
              messages: {
                sortingHint: intl.get('board.views.list.tooltips.sort'),
              },
            }}
            rowDetailing={{
              config: {
                contentComponent: ({ row }) => {
                  let content = '';
                  let versionKey = '';
                  let dateKey = '';
                  switch (row.type) {
                    case 'AUDIT_ACTION_ADDED_FILE':
                      return row.value.name;
                    case 'AUDIT_ACTION_FILE_NEW_VERSION':
                      content = intl.get('audit.action.new.version', {
                        name: row.value.name,
                        previous: row.value.previous_version,
                        version: row.value.new_version,
                      });
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_FILE_COPIED':
                      content = intl.get('audit.action.move', {
                        from: row.value.path,
                        name: row.value.name,
                      });
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_PROPERTY_CHANGED':
                    case 'AUDIT_ACTION_CREATE':
                      row.value.forEach(action => {
                        let value = action.new_value;

                        if (action.type === 'date') {
                          value = DateTimeFormatter({ value });
                        } else if (
                          action?.type === 'member' ||
                          action?.type === 'taskResponsible'
                        ) {
                          value = action?.new_value
                            ?.map(user => {
                              const member = members.find(m => m.id === user.id);
                              return member ? member.name : user.id;
                            })
                            .join(', ');
                        } else if (action.type === 'taskDone') {
                          value =
                            action.new_value === 'true'
                              ? intl.get('common.yes')
                              : intl.get('common.no');
                        }
                        /** Rich text needs special care because intl.get returns it in wrong format. */
                        if (action.type === 'richText') {
                          content += `<b>${intl.get('audit.action.changed.field')} </b> ${
                            action.name
                          } <b>${intl.get('audit.action.changed.new_value')} </b>${
                            action.new_value
                          } <br />`;
                        } else if (action.type === 'date' && action.new_value) {
                          content += intl.get('audit.action.changed.date', {
                            field: action.name,
                            date: parseISO(action.new_value),
                          });
                        } else {
                          content += intl.get('audit.action.changed.property', {
                            field: action.name,
                            value,
                          });
                        }
                      });
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_DELETE':
                      return row.value;
                    case 'AUDIT_ACTION_RENAME':
                    case 'AUDIT_ACTION_EDITED_LINK':
                      content = row.value.map(action => {
                        return intl.get('audit.action.old.new.value', {
                          ...action,
                        });
                      });
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_ADDED_LINK':
                      return row.value;
                    case 'AUDIT_ACTION_MOVED_LINK_FROM':
                    case 'AUDIT_ACTION_FILE_MOVED_FROM':
                      content = intl.get('audit.action.moved.from', {
                        name: row.value.name,
                        path: row.value.path,
                      });
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_MOVED_LINK_TO':
                    case 'AUDIT_ACTION_FILE_MOVED_TO':
                      content = intl.get('audit.action.moved.to', {
                        name: row.value.name,
                        path: row.value.path,
                      });
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_LINK_COPIED':
                      content = intl.get('audit.action.copied', {
                        name: row.value.name,
                        path: row.value.path,
                      });
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_DELETE_COMMENT':
                      return <div dangerouslySetInnerHTML={{ __html: row.value }} />;
                    case 'AUDIT_ACTION_ADD_COMMENT':
                      return <div dangerouslySetInnerHTML={{ __html: row.value }} />;
                    case 'AUDIT_ACTION_UPDATE_COMMENT':
                      content = getAuditActionCommentUpdatedContent(row);
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_ENTRY_MOVED':
                      content = getAuditActionEntryMovedContent(row);
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_COMMENT_MENTIONED_EMAIL_SENT':
                      content = getAuditActionCommentMentionedEmailSent(row);
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_EMAIL_SENT':
                      return row.value;
                    case 'AUDIT_ACTION_ENTRY_COPIED':
                      content = intl.get('audit.action.entry.copied', {
                        path: row.value.path,
                      });
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_CREATE_PUBLIC_LINK':
                    case 'AUDIT_ACTION_DELETE_PUBLIC_LINK':
                      content = intl.get('audit.action.public.link', {
                        name: row.value.name,
                        link:
                          ENVIRONMENTAL_VARIABLES.INTERAXO_URL +
                          '/prosjekthotell/public/?' +
                          row.value.id,
                      });
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_SHARE':
                      return row.value.receivers
                        .map(receiver => {
                          const user = members.find(m => m.id === receiver.id);

                          return user ? user.name : row.user.id;
                        })
                        .join(', ');
                    case 'AUDIT_ACTION_REVISE':
                      versionKey = Object.keys(row.value.version)[0];
                      dateKey = Object.keys(row.value.date)[0];
                      content =
                        versionKey +
                        ': ' +
                        row.value.version[versionKey] +
                        '<br />' +
                        dateKey +
                        ': ' +
                        row.value.date[dateKey];
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    default:
                      return '';
                  }
                },
              },
            }}
            providers={[
              <DataTypeProvider
                key={'dateTimeProvider'}
                for={['created']}
                formatterComponent={({ value }) => <DateTimeFormatter value={value} />}
              />,
            ]}
            tableConfig={{
              messages: {
                noData: <div className={classes.noData}>{intl.get('common.content.empty')}</div>,
              },
            }}
          />
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

Log.propTypes = {
  classes: PropTypes.shape({
    log: PropTypes.string,
    column: PropTypes.string,
    badge: PropTypes.string,
    heading: PropTypes.string,
    flexGrow: PropTypes.string,
    logColumn: PropTypes.string,
    noData: PropTypes.string,
  }).isRequired,
  log: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  ),
  item: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({})),
};

Log.defaultProps = {
  log: [],
  members: [],
};

export default withStyles(styles)(Log);
