import React, { useRef, useState, useEffect } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import saveAs from 'file-saver';

import { alertAction } from '../../thunks/Alerts';
import * as boardsActions from '../../thunks/Boards';
import * as cardActions from '../../thunks/Cards';
import Error from '../../common/Error';
import PageWrapper from '../../common/PageWrapper';
import {
  calculateDisabledFieldsForCard,
  getUpdatedFieldsAndValues,
  getRequiredFields,
  populateInitialValues,
  hasResponsibleField,
  getCardMetaInfo,
} from '../../utils/FieldUtil';
import { selectRoomMembers } from '../../ducks/Boards';
import {
  selectCard,
  selectAttachments,
  selectComments,
  selectLog,
  selectIsUploadingAttachments,
  selectIsDeletingAttachments,
  selectPossibleResponsible,
  actions as cardsActions,
} from '../../ducks/Cards';
import { selectActiveCommunity } from '../../ducks/Communities';
import { selectActiveRoom } from '../../ducks/Rooms';
import { downloadCardAttachment } from '../../services/Cards';
import { withViewModeAndStepQuery } from '../../utils/ViewModeUtil';
import Grid from '@material-ui/core/Grid/Grid';
import Form from '../common/Form';
import CardAttachments from '../common/Attachments';
import Log from '../common/Log';
import CardStep from './CardStep';
import CardComments from '../common/Comments';
import PropTypes from 'prop-types';
import { validateAnsweredDateField, validateAnswerField } from '../../utils/Validators';
import { TextareaAutosize } from '@material-ui/core';

const mapStateToProps = (state, ownProp) =>
  createStructuredSelector({
    activeCommunity: selectActiveCommunity(),
    activeRoom: selectActiveRoom(),
    card: selectCard({ cardId: ownProp.match.params.cardId }),
    attachments: selectAttachments(),
    comments: selectComments(),
    log: selectLog(),
    isUploading: selectIsUploadingAttachments(),
    isDeleting: selectIsDeletingAttachments(),
    possibleResponsible: selectPossibleResponsible(),
    roomMembers: selectRoomMembers(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...cardActions, ...cardsActions, ...boardsActions }, dispatch),
});

const CardUpdateContainer = ({
  activeCommunity,
  activeRoom,
  attachments,
  board,
  card,
  isUploading,
  isDeleting,
  comments,
  log,
  possibleResponsible,
  roomMembers,
  actions,
  location,
}) => {
  const formRef = useRef();
  const [closeOnSuccess, setCloseOnSuccess] = useState(false);
  const history = useHistory();
  const { boardId, cardId } = useParams();

  useEffect(() => {
    handleFetchComments({ boardId, cardId });
    handleFetchLog({ boardId, cardId });
    handleFetchAttachments({ boardId, cardId });

    if (roomMembers.length < 1 || board.id.toString() !== boardId) {
      handleFetchRoomMembers({ boardId });
    }

    // Possible responsible
    if (card?.fields) {
      const toValue = card.fields
        .find(field => field.id === board.field_config.to_member_field)
        .value.map(discipline => discipline.id);
      handleFetchPossibleResponsible({ disciplines: toValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardId, cardId]);

  const handleSubmit = ({ values, item }) => {
    return actions
      .updateCard({
        boardId: board.id,
        cardId: item.id,
        data: {
          type: 'entry',
          fields: getUpdatedFieldsAndValues({
            values,
            item,
            fieldsConfig: board.field_config.fields,
          }),
        },
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('card.update.error'),
          success: intl.get('card.update.success'),
          onSuccess: () =>
            history.push(
              withViewModeAndStepQuery(
                closeOnSuccess ? `/${board.id}` : `/${board.id}/cards/${action.payload.card.id}`,
              ),
            ),
        });
      });
  };

  const handleStepChange = async ({ card, step }) => {
    const form = formRef.current;

    if (form.dirty) {
      if (!form.isValid) {
        alertAction({
          action: { payload: { error: 'Invalid form' } },
          error: intl.get('card.move.error'),
        });
        return Promise.reject('Form is invalid');
      }

      return handleMoveAndUpdate({ card, step });
    }

    return handleMove({ card, step });
  };

  const handleMoveAndUpdate = ({ card, step }) => {
    const form = this.formRef.current;

    const updatedValues = getUpdatedFieldsAndValues({
      values: form.values,
      item: card,
      fieldsConfig: board.field_config.fields,
    });

    return actions
      .updateCardImmediately({
        boardId: board.id,
        card,
        stepId: step.id,
        data: updatedValues || [],
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('card.move.error'),
          success: intl.get('card.move_and_update.success'),
          onSuccess: () => history.push(withViewModeAndStepQuery(`/${board.id}`)),
        });
      });
  };

  const handleMove = ({ card, step }) => {
    const view = new URLSearchParams(location.search).get('view');

    return actions
      .moveCard({
        board,
        cardId: card.id,
        stepId: step.id,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('card.move.error'),
          success: intl.get('card.move.success'),
          onSuccess: () =>
            view ? history.push(`/${board.id}?view=${view}`) : history.push(`/${board.id}`),
        });
      });
  };

  const handleCancel = () => {
    history.push(withViewModeAndStepQuery(`/${board.id}`));
  };

  const handleUploadAttachment = ({ files }) => {
    if (files.length > 0) {
      actions
        .uploadAttachment({
          boardId: board.id,
          cardId: card.id,
          files,
        })
        .then(action => {
          alertAction({
            action,
            error: intl.get('common.attachments.upload.error'),
            success: intl.get('common.attachments.upload.success'),
          });
        });
    }
  };

  const handleAddLinks = links => {
    links.forEach(link =>
      actions
        .addLink({
          boardId: board.id,
          cardId: card.id,
          link,
        })
        .then(action => {
          alertAction({
            action,
            error: intl.get('common.link.add.error'),
            success: intl.get('common.link.add.success'),
          });
        }),
    );
  };

  const handleDeleteAttachment = ({ attachmentId }) => {
    actions
      .deleteAttachment({
        boardId: board.id,
        cardId: card.id,
        attachmentId,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('common.attachments.delete.error'),
          success: intl.get('common.attachments.delete.success'),
        });
      });
  };

  const handleDownloadAttachment = ({ attachment }) => {
    const boardId = board.id;
    const cardId = card.id;
    let attachmentId = attachment.id;
    let version;
    if (attachment.type === 'internal-link') {
      attachmentId = attachment.destination;
      version = attachment.destination_version;
    }
    downloadCardAttachment({
      boardId,
      cardId,
      attachmentId,
      version,
    }).then(response => {
      saveAs(response.data, attachment.name);
    });
  };

  const handleFetchPossibleResponsible = ({ disciplines }) => {
    if (hasResponsibleField({ fieldConfig: board.field_config })) {
      actions.fetchPossibleResponsible({ boardId: board.id, disciplines }).then(action =>
        alertAction({
          action,
          error: intl.get('card.responsible.error'),
        }),
      );
    }
  };

  const handleFetchRoomMembers = ({ boardId }) => {
    actions.fetchRoomMembers({ boardId });
  };

  const handleFetchComments = ({ boardId, cardId }) => {
    actions
      .fetchComments({
        boardId,
        cardId,
      })
      .then(action =>
        alertAction({
          action,
          error: intl.get('common.comments.fetch.error'),
        }),
      );
  };

  const handleFetchLog = ({ boardId, cardId }) => {
    actions
      .fetchLog({
        boardId,
        cardId,
      })
      .then(action =>
        alertAction({
          action,
          error: intl.get('common.log.fetch.error'),
        }),
      );
  };

  const handleFetchAttachments = ({ boardId, cardId }) => {
    actions
      .fetchAttachments({
        boardId,
        cardId,
      })
      .then(action =>
        alertAction({
          action,
          error: intl.get('common.attachments.fetch.error'),
        }),
      );
  };

  const handleSubmitComment = ({ content }) => {
    return actions
      .createComment({
        boardId: board.id,
        cardId: card.id,
        content,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('common.comments.add.error'),
          success: intl.get('common.comments.add.success'),
        });
      });
  };

  const handleUpdateComment = ({ comment }) => {
    return actions
      .updateComment({
        boardId: board.id,
        cardId: card.id,
        commentId: comment.id,
        comment,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('common.comments.update.error'),
          success: intl.get('common.comments.update.success'),
        });
      });
  };

  const handleDeleteComment = ({ comment }) => {
    return actions
      .deleteComment({
        boardId: board.id,
        cardId: card.id,
        commentId: comment.id,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('common.comments.delete.error'),
          success: intl.get('common.comments.delete.success'),
        });
      });
  };

  const handleSaveClick = ({ closeForm }) => {
    setCloseOnSuccess(!!closeForm);

    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  if (!card) {
    return (
      <Error
        text={intl.get('card.not_found', {
          cardId,
          boardId,
        })}
      />
    );
  }

  return (
    <PageWrapper title={intl.get('app_bar.question_card')}>
      <Grid container spacing={2}>
        <Grid item sm={12} md={8} xs={12}>
          <Form
            formRef={formRef}
            item={card}
            possibleResponsible={possibleResponsible}
            initialValues={populateInitialValues({
              item: card,
              fields: board.field_config.fields,
            })}
            disabledFields={calculateDisabledFieldsForCard({
              permissions: card.permissions,
              fieldConfig: board.field_config,
            })}
            disableDelete={true}
            requiredFields={getRequiredFields({
              item: card,
              fields: board.field_config.fields,
            })}
            type={board.type}
            fieldConfig={board.field_config}
            onFetchPossibleResponsible={handleFetchPossibleResponsible}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            restrictions={{
              'task-done': (fieldConfig, field) => ({}),
              'task-responsible': (fieldConfig, field) => ({}),
              'rich-text': (fieldConfig, field) => ({}),
              'auto-number': (fieldConfig, field) => ({}),
              'unique-document-id': (fieldConfig, field) => ({}),
              'sequence-number': (fieldConfig, field) => ({}),
              member: (fieldConfig, field) => ({}),
              list: (fieldConfig, field) => ({}),
              date: (fieldConfig, field) => {
                if (fieldConfig.answered_date_field === field.id) {
                  return { disableFuture: true };
                }
              },
              string: (fieldConfig, field) => {
                // question/answer field should be displayed as textarea if it's plain text field
                return (field.id === fieldConfig.question_field ||
                  field.id === fieldConfig.answer_field) &&
                  field.type === 'string'
                  ? {
                      variant: 'outlined',
                      InputLabelProps: { shrink: true },
                      InputProps: {
                        inputComponent: TextareaAutosize,
                        inputProps: { rowsMin: 5, rowsMax: 10 },
                        style: { minWidth: 512 },
                      },
                      style: { marginTop: 15 },
                    }
                  : {};
              },
              numeric: (fieldConfig, field) => ({}),
            }}
            formValidation={{
              answered_date_field: (fieldConfig, values) =>
                validateAnsweredDateField({ fieldConfig, values }),
              answer_field: (fieldConfig, values) => validateAnswerField({ fieldConfig, values }),
            }}
            metaInfo={getCardMetaInfo(card, roomMembers)}
            submitOptions={[
              {
                title: intl.get('common.form.save_and_close'),
                default: true,
                handleClick: () => handleSaveClick({ closeForm: true }),
              },
              {
                title: intl.get('common.form.save'),
                handleClick: () => handleSaveClick({ closeForm: false }),
              },
            ]}
          />
          <CardAttachments
            activeCommunity={activeCommunity}
            activeRoom={activeRoom}
            item={card}
            attachments={attachments}
            members={roomMembers}
            isUploading={isUploading}
            isDeleting={isDeleting}
            onAddLinks={handleAddLinks}
            onUploadAttachment={handleUploadAttachment}
            onDeleteAttachment={handleDeleteAttachment}
            onDownloadAttachment={handleDownloadAttachment}
          />
          <Log board={board} log={log} members={roomMembers} item={card} />
        </Grid>
        <Grid item sm={12} md={4} xs={12}>
          <CardStep card={card} steps={board.step_config.steps} onStepChange={handleStepChange} />
          <CardComments
            board={board}
            item={card}
            comments={comments}
            onSubmitComment={handleSubmitComment}
            onUpdateComment={handleUpdateComment}
            onDeleteComment={handleDeleteComment}
            members={roomMembers}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

CardUpdateContainer.propTypes = {
  activeCommunity: PropTypes.string.isRequired,
  activeRoom: PropTypes.string.isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  comments: PropTypes.arrayOf(PropTypes.shape({})),
  isUploading: PropTypes.bool,
  board: PropTypes.shape({
    workflow_config: PropTypes.shape({
      community_id: PropTypes.string,
      room_id: PropTypes.string,
    }),
    field_config: PropTypes.shape({
      to_member_field: PropTypes.string,
      fields: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    step_config: PropTypes.shape({
      steps: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
  }),
  card: PropTypes.shape({
    id: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape({})),
    permissions: PropTypes.shape({}),
  }),
  params: PropTypes.shape({
    boardId: PropTypes.string.isRequired,
    cardId: PropTypes.string.isRequired,
  }).isRequired,
  isDeleting: PropTypes.bool,
  possibleResponsible: PropTypes.arrayOf(PropTypes.shape({})),
  roomMembers: PropTypes.arrayOf(PropTypes.shape({})),
  actions: PropTypes.shape({
    fetchActiveBoard: PropTypes.func,
    fetchCards: PropTypes.func,
    updateCard: PropTypes.func,
    moveCard: PropTypes.func,
    updateCardImmediately: PropTypes.func,
    fetchPossibleResponsible: PropTypes.func,
    fetchRoomMembers: PropTypes.func,
    fetchComments: PropTypes.func,
    createComment: PropTypes.func,
    fetchAttachments: PropTypes.func,
    uploadAttachment: PropTypes.func,
    addLink: PropTypes.func,
    deleteAttachment: PropTypes.func,
    fetchLog: PropTypes.func,
    updateComment: PropTypes.func,
    deleteComment: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      boardId: PropTypes.string,
      cardId: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  log: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

CardUpdateContainer.defaultProps = {
  attachments: [],
  comments: [],
  isUploading: false,
  isLoadingBoard: false,
  isDeleting: false,
  possibleResponsible: [],
  roomMembers: [],
  match: {},
  history: {},
  location: {},
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardUpdateContainer));
