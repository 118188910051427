import React from 'react';
import intl from 'react-intl-universal';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import BoardCellConfig from './BoardCellConfig';
import BoardCellFilters from './BoardCellFilters';
import SubscriptionContainer from '../../common/SubscriptionContainer';
import FromToDatePicker from '../../common/FromToDatePicker';
import { subYears, addYears, format } from 'date-fns';
import PPCProgress from '../common/PPCProgressBar';
import PPCPeriodPicker from '../../common/PPCPeriodPicker';
import { getLocale } from '../../utils/LocaleUtil';

const styles = theme => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
  },
  formControl: {
    marginLeft: 25,
  },
  flexGrow: {
    flex: '1 1 auto',
  },
  fixedWidthBlock: {
    width: 10,
  },
  margin: {
    margin: theme.spacing(1),
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
});

const locale = intl.getInitOptions().currentLocale;

const Toolbar = ({
  classes,
  board,
  viewConfig,
  onChange,
  onCalculatePPC,
  onExpandAll,
  onCollapseAll,
  onExpandAllCells,
  onCollapseAllCells,
  expandedRows,
  expandedCells,
  subscription,
  possibleResponsible,
  viewName,
  renderCreateCardButton,
  onDateChange,
  width,
}) => {
  const toggleHighReadability = () => {
    onChange(
      viewConfig
        .set('highReadability', !viewConfig.highReadability)
        .set('stepsSidebarOpen', !viewConfig.highReadability ? false : viewConfig.stepsSidebarOpen),
    );
  };

  const handleClickPPCButton = () => {
    onChange(viewConfig.set('showPPC', !viewConfig.showPPC));
  };

  const showPPC = viewConfig.showPPC;
  const minDate = onDateChange && subYears(viewConfig.dateTo, 6);

  let toolbarWidth = 'calc(100% - 340px)';
  if (isWidthDown('sm', width)) {
    toolbarWidth = '100%';
  }

  return (
    <div className={classes.toolbar} style={{ width: toolbarWidth }}>
      {renderCreateCardButton()}
      {onDateChange && (
        <Hidden smDown>
          {!isWidthDown('sm', width) && <div className={classes.fixedWidthBlock} />}
          <FromToDatePicker
            minDateFrom={minDate}
            maxDateFrom={viewConfig.dateTo}
            valueFrom={viewConfig.dateFrom}
            minDateTo={viewConfig.dateFrom}
            maxDateTo={addYears(new Date(viewConfig.dateFrom), 6)}
            valueTo={viewConfig.dateTo}
            dateTo={viewConfig.dateTo}
            handleChange={onDateChange}
          />
        </Hidden>
      )}

      {onCalculatePPC && (
        <Tooltip title={intl.get('board.toolbar.ppc.calculate')} style={{ paddingLeft: 25 }}>
          <Button onClick={handleClickPPCButton}>
            {intl.get('board.toolbar.ppc.shorten')}
            {showPPC ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Tooltip>
      )}
      {onCalculatePPC && <PPCPeriodPicker viewConfig={viewConfig} />}
      {showPPC && onCalculatePPC && (
        <PPCProgress
          title={intl.get('board.toolbar.ppc.total')}
          viewConfig={viewConfig}
          style={{ width: 130 }}
          ppc={onCalculatePPC(viewConfig.ppc_dateFrom, viewConfig.ppc_dateTo)}
          dateFrom={format(viewConfig.ppc_dateFrom, 'eee, dd MMM yyyy', {
            locale: getLocale(locale),
          })}
          dateTo={format(viewConfig.ppc_dateTo, 'eee, dd MMM yyyy', {
            locale: getLocale(locale),
          })}
        />
      )}
      <div className={classes.flexGrow} />
      <Hidden smDown>
        {onExpandAll && expandedRows.length === 0 && (
          <Tooltip title={intl.get('board.toolbar.expand.tooltip')}>
            <Button id={'expand-rows'} onClick={onExpandAll}>
              {intl.get('board.toolbar.expand')}
              <ExpandMore />
            </Button>
          </Tooltip>
        )}
        {onCollapseAll && expandedRows.length > 0 && (
          <Tooltip title={intl.get('board.toolbar.collapse.tooltip')}>
            <Button id={'collapse-rows'} onClick={onCollapseAll}>
              {intl.get('board.toolbar.collapse')}
              <ExpandLess />
            </Button>
          </Tooltip>
        )}
        {onExpandAllCells && expandedCells.length === 0 && (
          <Tooltip title={intl.get('board.toolbar.cells.expand.tooltip')}>
            <Button id={'expand-cells'} onClick={onExpandAllCells}>
              {intl.get('board.toolbar.cells.expand')}
              <ExpandMore />
            </Button>
          </Tooltip>
        )}
        {onCollapseAllCells && expandedCells.length > 0 && (
          <Tooltip title={intl.get('board.toolbar.cells.collapse.tooltip')}>
            <Button id={'collapse-cells'} onClick={onCollapseAllCells}>
              {intl.get('board.toolbar.cells.collapse')}
              <ExpandLess />
            </Button>
          </Tooltip>
        )}
        <Tooltip
          title={
            viewConfig.highReadability
              ? intl.get('board.toolbar.zoom.out.tooltip')
              : intl.get('board.toolbar.zoom.in.tooltip')
          }>
          <IconButton onClick={toggleHighReadability}>
            {viewConfig.highReadability ? <ZoomOut /> : <ZoomIn />}
          </IconButton>
        </Tooltip>
      </Hidden>
      <BoardCellFilters
        onChange={onChange}
        board={board}
        viewConfig={viewConfig}
        viewName={viewName}
        possibleResponsible={possibleResponsible}
      />
      {subscription && <SubscriptionContainer id={board.id} />}
      <BoardCellConfig
        onChange={onChange}
        board={board}
        viewConfig={viewConfig}
        viewName={viewName}
      />
    </div>
  );
};

Toolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  board: PropTypes.object.isRequired,
  viewConfig: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onCalculatePPC: PropTypes.func,
  onExpandAll: PropTypes.func.isRequired,
  onCollapseAll: PropTypes.func.isRequired,
  onExpandAllCells: PropTypes.func.isRequired,
  onCollapseAllCells: PropTypes.func.isRequired,
  expandedRows: PropTypes.array.isRequired,
  expandedCells: PropTypes.array.isRequired,
  subscription: PropTypes.bool,
  possibleResponsible: PropTypes.array.isRequired,
  viewName: PropTypes.string.isRequired,
  onDateChange: PropTypes.func,
  renderCreateCardButton: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  onClickCreateCard: PropTypes.func,
};

Toolbar.defaultProps = {
  onClickCreateCard: () => {},
};

export default withStyles(styles)(withWidth()(Toolbar));
