import React, { useState } from 'react';
import classNames from 'classnames';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { default as SpinnerButton } from '../../common/button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { canMoveToStep, isInStep } from '../../utils/PermissionUtils';

const styles = theme => ({
  cardStep: {
    marginBottom: 20,
  },
  currentStep: {
    backgroundColor: '#fff9c4',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  lockIcon: {
    fontSize: theme.typography.pxToRem(20),
    paddingRight: theme.spacing(1),
  },
});

const CardStep = ({ classes, card, steps, onStepChange }) => {
  const [movingToStep, setMovingToStep] = useState(null);

  const handleStepChange = step => {
    setMovingToStep(step.id);
    onStepChange({ step, card })
      .then(() => {
        setMovingToStep(null);
      })
      .catch(() => {
        setMovingToStep(null);
      });
  };

  return (
    <ExpansionPanel disabled={!card.id} defaultExpanded={!!card.id} className={classes.cardStep}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>{intl.get('card.form.move_to')}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelActions>
        <Grid container>
          {card.step &&
            steps.map(step => (
              <Grid item key={`step-container-${step.id}`}>
                <Tooltip title={intl.get('card.move.tooltip')}>
                  <SpinnerButton
                    variant="outlined"
                    className={classNames(isInStep({ step, card }) && classes.currentStep)}
                    onClick={() => handleStepChange(step)}
                    spinner={movingToStep && movingToStep === step.id}
                    disabled={!canMoveToStep({ step, card }) || movingToStep}>
                    {step.final && <LockIcon className={classes.lockIcon} />}
                    {step.name}
                  </SpinnerButton>
                </Tooltip>
              </Grid>
            ))}
        </Grid>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
};

CardStep.propTypes = {
  classes: PropTypes.shape({
    cardStep: PropTypes.string,
    heading: PropTypes.string,
    currentStep: PropTypes.string,
    lockIcon: PropTypes.string,
  }).isRequired,
  card: PropTypes.shape({
    id: PropTypes.string,
    step: PropTypes.shape({}),
  }).isRequired,
  stepConfig: PropTypes.shape({}),
  steps: PropTypes.arrayOf(PropTypes.shape({})),
  onStepChange: PropTypes.func,
};

CardStep.defaultProps = {
  stepConfig: {},
  steps: [],
  onStepChange: () => {},
};

export default withStyles(styles)(CardStep);
