import React from 'react';
import intl from 'react-intl-universal';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Immutable from 'seamless-immutable';

const styles = {
  menuList: {
    outline: 'none',
  },
  resetFilter: {
    marginLeft: 24,
    height: 32,
  },
  field: {
    marginLeft: 16,
    marginRight: 16,
    height: 48,
  },
};

const DisciplineFilters = ({
  classes = {},
  viewConfig,
  onChange = () => {},
  hasDisciplineFilters,
  fromMemberField,
  toMemberField,
}) => {
  const handleFilterChange = event => {
    onChange(viewConfig.set(event.target.name, event.target.value));
  };

  const handleResetFilter = () => {
    onChange(viewConfig.set('from', null).set('to', null));
  };

  return (
    <React.Fragment>
      <ListSubheader style={{ outline: 'none' }}>
        {intl.get('board.toolbar.discipline_filters')}
        <Button
          className={classes.resetFilter}
          size="small"
          variant="outlined"
          disabled={!hasDisciplineFilters}
          onClick={handleResetFilter}>
          {intl.get('board.toolbar.discipline_filters.reset')}
        </Button>
      </ListSubheader>
      <MenuItem className={classes.field}>
        <FormControl style={{ minWidth: 250, maxWidth: 250 }}>
          <InputLabel htmlFor="from-label-placeholder">
            {intl.get('board.toolbar.discipline_filters.from')}
          </InputLabel>
          <Select
            multiple
            value={Immutable(viewConfig.from || fromMemberField.limited_to).asMutable()}
            onChange={handleFilterChange}
            MenuProps={{
              getContentAnchorEl: () => null,
            }}
            inputProps={{
              name: 'from',
              id: 'from-label-placeholder',
            }}>
            {fromMemberField.limited_to.map(group => (
              <MenuItem key={group.name} value={group}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </MenuItem>
      <MenuItem className={classes.field}>
        <FormControl style={{ minWidth: 250, maxWidth: 250 }}>
          <InputLabel htmlFor="to-label-placeholder">
            {intl.get('board.toolbar.discipline_filters.to')}
          </InputLabel>
          <Select
            multiple
            value={Immutable(viewConfig.to || toMemberField.limited_to).asMutable()}
            onChange={handleFilterChange}
            MenuProps={{
              getContentAnchorEl: () => null,
            }}
            inputProps={{
              name: 'to',
              id: 'to-label-placeholder',
            }}>
            {toMemberField.limited_to.map(group => (
              <MenuItem key={group.name} value={group}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </MenuItem>
    </React.Fragment>
  );
};

DisciplineFilters.propTypes = {
  viewConfig: PropTypes.object.isRequired,
  hasDisciplineFilters: PropTypes.bool.isRequired,
  fromMemberField: PropTypes.object.isRequired,
  toMemberField: PropTypes.object.isRequired,
  classes: PropTypes.shape({
    resetFilter: PropTypes.string,
    field: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

DisciplineFilters.defaultProps = {
  onChange: () => {},
  classes: {},
};

export default withStyles(styles)(DisciplineFilters);
