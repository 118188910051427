import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import { renderSelectField } from '../../utils/RenderUtil';
import { filterOnNoValue, isAnyFilterAvailable, getFieldFilterId } from '../../utils/FilterUtil';

const styles = {
  divider: {
    marginTop: 10,
  },
  menuList: {
    outline: 'none',
  },
  resetFilter: {
    marginLeft: 24,
    height: 32,
  },
  field: {
    marginLeft: 16,
    marginRight: 16,
    paddingBottom: 3,
    height: 48,
  },
};

const FieldFilters = props => {
  const { classes, viewConfig, fields, type, title, resetTitle, onChange } = props;

  const handleFilterChange = event => {
    onChange(viewConfig.setIn(['field_filters', type, event.target.name], event.target.value));
  };

  const handleResetFilter = () => {
    onChange(viewConfig.setIn(['field_filters', type], null));
  };

  const listField = ({ field, viewConfig, style, type }) => {
    const fieldFilterId = getFieldFilterId(field);
    let options = field.options || field.limited_to;

    options = options.asMutable();
    options.push({
      id: filterOnNoValue,
      label: intl.get('board.toolbar.filters.no_value'),
    });
    return [
      renderSelectField({
        style,
        name: fieldFilterId,
        label: field.name,
        value:
          (viewConfig.field_filters[type] && viewConfig.field_filters[type][fieldFilterId]) || [],
        handleChange: handleFilterChange,
        options: options,
        multiple: true,
      }),
    ];
  };

  return (
    <div style={{ outline: 'none' }}>
      <Divider className={classes.divider} />
      <ListSubheader className={classes.menuList}>
        {title}
        <Button
          className={classes.resetFilter}
          size="small"
          variant="outlined"
          disabled={!isAnyFilterAvailable(viewConfig.field_filters[type])}
          onClick={handleResetFilter}>
          {resetTitle}
        </Button>
      </ListSubheader>
      {fields.map(field => (
        <MenuItem key={field.name} className={classes.field}>
          {listField({
            style: { width: 250 },
            field,
            viewConfig,
            type,
          })}
        </MenuItem>
      ))}
    </div>
  );
};

FieldFilters.propTypes = {
  viewConfig: PropTypes.object.isRequired,
  taskResponsible: PropTypes.object,
  type: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    divider: PropTypes.string,
    menuList: PropTypes.string,
    resetFilter: PropTypes.string,
    field: PropTypes.string,
  }).isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string.isRequired,
  resetTitle: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

FieldFilters.defaultProps = {
  onChange: () => {},
};

export default withStyles(styles)(FieldFilters);
