import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import intl from 'react-intl-universal';
import queryString from 'query-string';

import LinearProgress from '@material-ui/core/LinearProgress';
import Boards from './Boards';
import * as boardsActions from '../../thunks/Boards';
import { actions as communityActions } from '../../ducks/Communities';
import { actions as roomActions } from '../../ducks/Rooms';
import * as communitiesActions from '../../thunks/Communities';
import * as roomsActions from '../../thunks/Rooms';
import PageWrapper from '../../common/PageWrapper';
import { selectIsLoadingBoards, selectBoards, selectIsRequested } from '../../ducks/Boards';
import {
  selectCommunities,
  selectActiveCommunity,
  selectIsLoadingCommunities,
  selectWasRequested,
} from '../../ducks/Communities';
import {
  selectRooms,
  selectActiveRoom,
  selectIsLoadingRooms,
  selectRequestedCommunity,
} from '../../ducks/Rooms';
import { withStyles } from '@material-ui/core/styles';
import { renderSelectField } from '../../utils/RenderUtil';

const styles = {
  field: {
    marginRight: 20,
    minWidth: 200,
  },
  loading: {
    marginTop: 15,
  },
};

const mapStateToProps = () =>
  createStructuredSelector({
    boards: selectBoards(),
    isLoading: createSelector(
      selectIsLoadingBoards(),
      selectIsLoadingCommunities(),
      selectIsLoadingRooms(),
      (isLoadingBoards, isLoadingCommunities, isLoadingRooms) =>
        isLoadingBoards || isLoadingCommunities || isLoadingRooms,
    ),
    isBoardsRequested: selectIsRequested(),
    communities: selectCommunities(),
    communityId: selectActiveCommunity(),
    rooms: selectRooms(),
    roomId: selectActiveRoom(),
    wasCommunityRequested: selectWasRequested(),
    roomsRequestedCommunity: selectRequestedCommunity(),
  });

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      ...boardsActions,
      ...communitiesActions,
      ...roomsActions,
      setActiveCommunity: communityActions.setActiveCommunity,
      setActiveRoom: roomActions.setActiveRoom,
    },
    dispatch,
  ),
});

const BoardsContainer = props => {
  const {
    communityId,
    roomId,
    isBoardsRequested,
    isLoading,
    boards,
    communities,
    rooms,
    classes,
    location,
    history,
    setActiveCommunity,
    setActiveRoom,
    fetchBoards,
    fetchRooms,
    roomsRequestedCommunity,
  } = props;

  const resolveCommunityIdFromUrl = () => queryString.parse(location.search).communityId || '';
  const resolveRoomIdFromUrl = () => queryString.parse(location.search).roomId || '';

  const setupRoomsByCommunity = (communityId, roomId) => {
    if (roomId) {
      setActiveRoom({ roomId });
    }
    if (roomsRequestedCommunity !== communityId) {
      fetchRooms({ communityId });
    }
  };

  const shouldChangeHistory = (currentCommunityId, currentRoomId) =>
    resolveCommunityIdFromUrl() !== currentCommunityId || resolveRoomIdFromUrl() !== currentRoomId;

  const changeHistory = (currentCommunityId, currentRoomId) => {
    if (shouldChangeHistory(currentCommunityId, currentRoomId)) {
      const newPath = currentRoomId
        ? `?communityId=${currentCommunityId}&roomId=${currentRoomId}`
        : `?communityId=${currentCommunityId}`;

      history.push(newPath);
    }
  };

  useEffect(() => {
    const roomIdFromUrl = resolveRoomIdFromUrl();
    let currentRoomId = roomIdFromUrl || roomId;

    setupRoomsByCommunity(communityId, currentRoomId);
    changeHistory(communityId, currentRoomId);

    if (
      currentRoomId &&
      (isBoardsRequested.roomId !== currentRoomId || isBoardsRequested.communityId !== communityId)
    ) {
      fetchBoards({ communityId, roomId: currentRoomId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setupRoomsByCommunity(communityId, roomId);
    changeHistory(communityId, roomId);

    if (
      roomId &&
      (isBoardsRequested.roomId !== roomId || isBoardsRequested.communityId !== communityId)
    ) {
      fetchBoards({ communityId, roomId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId, roomId]);

  const handleCommunityChange = event => {
    setActiveCommunity({ communityId: event.target.value });
    setActiveRoom({ roomId: '' });
    changeHistory(event.target.value, '');
  };

  const handleRoomChange = event => {
    setActiveRoom({ roomId: event.target.value });
    changeHistory(communityId, event.target.value);
  };

  const communityOptions = communities.map(community => {
    let label = community.name;

    if (community.project_name) {
      label = community.name + ' - ' + community.project_name;
    }

    if (community.name === '') {
      label = community.id;
    }

    return {
      id: community.id,
      name: label,
    };
  });

  return (
    <React.Fragment>
      <PageWrapper title={intl.get('app_bar.boards')}>
        {renderSelectField({
          name: 'communityId',
          label: intl.get('boards.community'),
          value: communityId,
          handleChange: handleCommunityChange,
          options: communityOptions,
          className: classes.field,
        })}
        {renderSelectField({
          name: 'roomId',
          label: intl.get('boards.room'),
          value: roomId,
          handleChange: handleRoomChange,
          options: rooms,
          className: classes.field,
        })}
        {isLoading && <LinearProgress className={classes.loading} />}
        {!isLoading && (
          <Boards
            history={history}
            communityId={communityId}
            communities={communities}
            roomId={roomId}
            rooms={rooms}
            boards={boards}
          />
        )}
      </PageWrapper>
    </React.Fragment>
  );
};

BoardsContainer.propTypes = {
  isBoardsRequested: PropTypes.shape({
    roomId: PropTypes.string,
    communityId: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
  wasCommunityRequested: PropTypes.bool.isRequired,
  communities: PropTypes.arrayOf(PropTypes.shape({})),
  rooms: PropTypes.arrayOf(PropTypes.shape({})),
  boards: PropTypes.arrayOf(PropTypes.shape({})),
  communityId: PropTypes.string.isRequired,
  roomsRequestedCommunity: PropTypes.string.isRequired,
  roomId: PropTypes.string.isRequired,
  setActiveCommunity: PropTypes.func.isRequired,
  setActiveRoom: PropTypes.func.isRequired,
  fetchCommunities: PropTypes.func.isRequired,
  fetchBoards: PropTypes.func.isRequired,
  fetchRooms: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.shape({
    field: PropTypes.string,
    loading: PropTypes.string,
  }),
};

BoardsContainer.defaultProps = {
  communities: [],
  rooms: [],
  boards: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BoardsContainer));
