import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import LinearProgress from '@material-ui/core/LinearProgress';
import { default as Table } from '../../common/table';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import Immutable from 'seamless-immutable';

import DeleteIconButton from '../../common/DeleteIconButton';
import { DateFormatter } from '../../common/DateFormatter';
import { getIcon } from '../../utils/IconMappingUtil';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

const styles = theme => ({
  attachmentColumn: {
    flexBasis: '100%',
  },
  noData: {
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    transform: 'none',
  },
});

const DeleteButton = ({ onExecute }) => (
  <DeleteIconButton tooltipText={intl.get('common.attachments.delete')} onClick={onExecute} />
);

DeleteButton.propTypes = {
  onExecute: PropTypes.func.isRequired,
};

const AttachmentsDesktop = ({
  classes,
  attachments = [],
  members = [],
  item,
  isUploading = false,
  isDeleting,
  formatAttachmentLink = () => {},
  commitChanges = () => {},
  onUploadAttachment = () => {},
}) => {
  return (
    <ExpansionPanelDetails>
      <div className={classes.attachmentColumn}>
        {(isUploading || isDeleting) && <LinearProgress />}
        <Dropzone
          style={{ backgroundImage: '' }}
          multiple
          disableClick
          disablePreview
          disabled={!item.permissions.ADD_ATTACHMENT}
          onDrop={onUploadAttachment}>
          <Table
            rows={Immutable(attachments || []).asMutable()}
            columns={[
              {
                name: 'icon',
                title: ' ',
                width: 50,
                getCellValue: file => getIcon(file),
              },
              {
                name: 'name',
                title: intl.get('common.attachments.columns.name'),
              },
              {
                name: 'created',
                title: intl.get('common.attachments.columns.created'),
              },
              {
                name: 'created_by',
                title: intl.get('common.attachments.columns.created_by'),
                getCellValue: file => {
                  const user = members.find(m => m.id === file.created_by.id);
                  if (user) {
                    return user.name;
                  }
                  return file.created_by.id;
                },
              },
            ]}
            editing={{
              stateConfig: {
                onCommitChanges: commitChanges,
              },
              config: {
                width: 120,
                showDeleteCommand: item.permissions.DELETE_ATTACHMENT,
                commandComponent: DeleteButton,
              },
            }}
            providers={[
              <DataTypeProvider
                key={'dateProvider'}
                for={['created']}
                formatterComponent={({ value }) => <DateFormatter value={value} />}
              />,
              <DataTypeProvider
                key={'linkProvider'}
                for={['name']}
                formatterComponent={formatAttachmentLink}
              />,
            ]}
            tableConfig={{
              messages: {
                noData: <div className={classes.noData}>{intl.get('common.content.empty')}</div>,
              },
            }}
          />
        </Dropzone>
      </div>
    </ExpansionPanelDetails>
  );
};

AttachmentsDesktop.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  item: PropTypes.shape({
    permissions: PropTypes.shape({
      DELETE_ATTACHMENT: PropTypes.bool,
      ADD_ATTACHMENT: PropTypes.bool,
    }),
  }).isRequired,
  isUploading: PropTypes.bool,
  isDeleting: PropTypes.bool,
  formatAttachmentLink: PropTypes.func,
  commitChanges: PropTypes.func,
  onUploadAttachment: PropTypes.func,
  onExecute: PropTypes.func,
  classes: PropTypes.shape({
    noData: PropTypes.string,
    attachmentColumn: PropTypes.string,
  }),
  members: PropTypes.arrayOf(PropTypes.shape({})),
};

export default withStyles(styles)(AttachmentsDesktop);
