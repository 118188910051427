import React, { useRef, useState } from 'react';
import { Formik } from 'formik';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Gravatar from 'react-gravatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { default as Editor } from '../../common/richtext';
import RootRef from '@material-ui/core/RootRef';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { DateTimeFormatter } from '../../common/DateFormatter';
import DeleteDeliveryDialogue from '../../common/DeleteCardItemDialog';
import IconButton from '@material-ui/core/IconButton';
import { Delete, EditOutlined } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import Immutable from 'seamless-immutable';
import { authoritySorter } from '../../utils/SortUtil';

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
  },
  badge: {
    top: '-4px',
    right: '-30px',
    transform: 'scale(1)',
  },
  commentLabel: {
    position: 'absolute',
    left: 40,
    top: 145,
    zIndex: 1000,
  },
  paper: {
    boxShadow: 0,
    margin: `15px auto`,
    padding: 10,
  },
  comment: {
    '&:first-child': {
      paddingLeft: 15,
    },
  },
  iconPadding: {
    padding: 8,
  },
});

const Comments = ({
  classes,
  item,
  comments,
  board,
  members,
  onSubmitComment,
  onUpdateComment,
  onDeleteComment,
}) => {
  const [editorExpanded, setEditorExpanded] = useState(false);
  const [commentToEdit, setCommentToEdit] = useState(null);
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);

  const commentCaption = useRef(null);

  const handleSubmit = (values, actions) => {
    closeEditor();
    actions.setSubmitting(true);

    if (commentToEdit) {
      return onUpdateComment({
        comment: commentToEdit.merge({
          content: values['content'],
        }),
      }).then(() => {
        setCommentToEdit(null);
        actions.resetForm({});
        actions.setSubmitting(false);
      });
    }

    onSubmitComment({ content: values['content'] }).then(() => {
      actions.resetForm({});
      actions.setSubmitting(false);
    });
  };

  const handleEditComment = ({ comment }) => {
    setCommentToEdit(comment);
  };

  const handleDeleteComment = ({ comment }) => {
    setOpenDeleteDialogue(true);
    setCommentToDelete(comment);
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialogue(false);
    setCommentToDelete(null);
  };

  const handleDeleteSubmit = () => {
    if (commentToDelete) {
      onDeleteComment({ comment: commentToDelete });
    }
    handleCloseDelete();
  };

  const handleCancelEditComment = () => {
    setCommentToEdit(null);
  };

  const validate = values => {
    let errors = {};
    if (!values.content || values.content === ' ') {
      errors['content'] = intl.get('common.form.validators.required');
    }
    return errors;
  };

  const handleFocus = () => {
    setEditorExpanded(true);
  };

  const closeEditor = () => {
    setEditorExpanded(false);
  };

  const fetchMentions = (searchTerm, callback) => {
    const mentions = Immutable.asMutable(members)
      .filter(member => member.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)
      .sort(authoritySorter(searchTerm))
      .map(member => ({
        id:
          member.type === 'group'
            ? member.id
            : `${member.id.replace('@', '~')}@${board.workflow_config.community_id}`,
        name: member.name,
        rawname: member.name,
      }));
    callback(mentions, []);
  };

  const newComments = comments.filter(comment => item.id === comment.cardId);

  return (
    <ExpansionPanel
      disabled={!item.id}
      defaultExpanded={!!item.id}
      onChange={(event, expanded) => {
        if (commentCaption && commentCaption.current) {
          commentCaption.current.style.display = expanded ? '' : 'none';
        }
      }}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <React.Fragment>
          <Badge
            classes={{ badge: classes.badge }}
            color="primary"
            badgeContent={newComments.length}>
            <Typography className={classes.heading}>{intl.get('common.comments')}</Typography>
          </Badge>
        </React.Fragment>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.form}>
        <Formik
          onSubmit={handleSubmit}
          validate={validate}
          enableReinitialize
          initialValues={{
            content: commentToEdit ? commentToEdit.content : ' ',
          }}
          render={({ values, handleSubmit, isSubmitting, isValid, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              {!editorExpanded && !isSubmitting && item.permissions.ADD_COMMENT && (
                <RootRef rootRef={commentCaption}>
                  <Typography variant="caption" className={classes.commentLabel}>
                    {intl.get('common.comments.label')}
                  </Typography>
                </RootRef>
              )}
              <Editor
                id="commentField"
                value={values.content || ' '}
                onChange={(event, editor) => setFieldValue('content', editor.getContent())}
                onFocus={handleFocus}
                enableMentions
                mentionsFetch={fetchMentions}
                disabled={!item.permissions.ADD_COMMENT}
                height={editorExpanded ? 250 : 100}
                style={{ marginBottom: 20 }}
              />
              {editorExpanded && (
                <div>
                  {commentToEdit ? (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting || !isValid || !item.permissions.ADD_COMMENT}>
                      {intl.get('common.comments.update')}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting || !isValid || !item.permissions.ADD_COMMENT}>
                      {intl.get('common.comments.add')}
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginLeft: 16 }}
                    disabled={isSubmitting || !item.permissions.ADD_COMMENT}
                    onClick={() => {
                      setFieldValue('content', ' ');
                      handleCancelEditComment();
                      closeEditor();
                    }}>
                    {intl.get('common.dialog.cancel')}
                  </Button>
                  {isSubmitting && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                  )}
                </div>
              )}
            </form>
          )}
        />
        <Grid>
          {newComments.map((comment, index) => (
            <Paper key={`comment-${index}`} className={classes.paper}>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <Avatar>
                    <Gravatar email={comment.author || ''} default="mm" />
                  </Avatar>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Tooltip title={intl.get('common.comments.update')}>
                    <IconButton
                      className={classes.iconPadding}
                      disabled={!comment.permissions.UPDATE}
                      onClick={() => {
                        handleFocus();
                        handleEditComment({ comment });
                      }}>
                      <EditOutlined />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={intl.get('common.comments.delete')}>
                    <IconButton
                      className={classes.iconPadding}
                      disabled={!comment.permissions.DELETE}
                      onClick={() => handleDeleteComment({ comment })}>
                      <Delete />
                    </IconButton>
                  </Tooltip>

                  <DeleteDeliveryDialogue
                    open={openDeleteDialogue}
                    onClose={handleCloseDelete}
                    onDelete={handleDeleteSubmit}
                    titles={{
                      deleteDialogTitle: intl.get('common.comments.delete.tooltip.title'),
                      deleteDialogMessage: intl.get('common.comments.delete.tooltip.message'),
                    }}
                  />

                  <ListItemText
                    classes={{
                      root: classes.comment,
                    }}
                    primary={<div dangerouslySetInnerHTML={{ __html: comment.content }} />}
                    secondary={intl.get('common.comments.posted_by', {
                      name: comment.created_by.name,
                      date: DateTimeFormatter({ value: comment.modified }),
                    })}
                  />
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

Comments.propTypes = {
  board: PropTypes.shape({
    workflow_config: PropTypes.shape({
      community_id: PropTypes.string,
    }),
  }),
  classes: PropTypes.shape({
    badge: PropTypes.string,
    buttonProgress: PropTypes.string,
    comment: PropTypes.string,
    commentLabel: PropTypes.string,
    heading: PropTypes.string,
    form: PropTypes.string,
    paper: PropTypes.string,
    wrapper: PropTypes.string,
    iconPadding: PropTypes.string,
  }).isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
    permissions: PropTypes.shape({
      ADD_COMMENT: PropTypes.bool,
    }),
  }).isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmitComment: PropTypes.func,
  onCommentEdit: PropTypes.func,
  onCancelEdit: PropTypes.func,
  onUpdateComment: PropTypes.func,
  onDeleteComment: PropTypes.func,
};

Comments.defaultProps = {
  board: null,
  comments: [],
  members: [],
  onSubmitComment: () => {},
  onUpdateComment: () => {},
  onDeleteComment: () => {},
};

export default withStyles(styles)(Comments);
