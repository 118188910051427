import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  flexWrapper: {
    display: 'flex',
  },
  relativeWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  progress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

const SpinnerButton = ({
  classes: { flexWrapper, relativeWrapper, progress, ...restClasses },
  spinner,
  disabled,
  children,
  ...rest
}) => (
  <div className={flexWrapper}>
    <div className={relativeWrapper}>
      <Button classes={restClasses} disabled={spinner || disabled} {...rest}>
        {children}
      </Button>
      {spinner && <CircularProgress size={24} className={progress} />}
    </div>
  </div>
);

SpinnerButton.propTypes = {
  classes: PropTypes.object,
  spinner: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default withStyles(styles)(SpinnerButton);
