import React from 'react';
import PropTypes from 'prop-types';
import { isCardInPPCPeriod } from '../utils/FieldUtil';

const CardList = ({
  board,
  viewConfig,
  rowIndex,
  columnIndex,
  items,
  CardComponent,
  onCardClick,
  type,
}) => {
  const isMarked = item => {
    return viewConfig.showPPC && isCardInPPCPeriod(item, board, viewConfig);
  };

  return (
    <React.Fragment>
      {items.map(item => (
        <CardComponent
          key={item.id}
          card={item}
          board={board}
          viewConfig={viewConfig}
          onCardClick={onCardClick}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          isMarked={isMarked(item)}
          type={type}
        />
      ))}
    </React.Fragment>
  );
};

CardList.propTypes = {
  classes: PropTypes.shape({}),
  board: PropTypes.shape({}).isRequired,
  viewConfig: PropTypes.shape({
    showPPC: PropTypes.bool,
  }).isRequired,
  rowIndex: PropTypes.number,
  columnIndex: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // Displays the items in this list. You may use QuestionCardItem, DeliveryCardItem or
  // MilestoneCardItem.
  CardComponent: PropTypes.shape({}).isRequired,
  onCardClick: PropTypes.func,
  type: PropTypes.string.isRequired,
};

CardList.defaultProps = {
  onCardClick: () => {},
  rowIndex: 0,
  columnIndex: 0,
};

export default CardList;
