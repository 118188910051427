import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import red from '@material-ui/core/colors/red';
import { DISPLAY_ENTRY_TITLE } from '../../utils/MilestoneUtil';
import { getEntryTitle } from '../../utils/FieldUtil';
import { WEEK_BOARD_VIEW_MODE } from '../../utils/ViewModeUtil';

const styles = {
  settingsAdjusted: {
    color: red[800],
  },
  menu: {
    paddingBottom: 24,
  },
  field: {
    marginLeft: 24,
    marginRight: 24,
    height: 48,
  },
};

// Return an array of fields that all have the correct type to be displayed in a card header. The returned fields will
// be a subset of the full selection of fields, as given in fieldList.
function filterTitleFieldTypes(fieldList) {
  return fieldList.filter(
    field =>
      field.type === 'string' ||
      field.type === 'date' ||
      field.type === 'numeric' ||
      field.type === 'auto-number',
  );
}

// Search the given list of milestone fields, and return true if any of them matches the given target field. The fields
// match if they have the same name and type.
function containsField(fieldList, targetField) {
  return fieldList.some(
    field => field.name === targetField.name && field.type === targetField.type,
  );
}

// Return a list of field objects that can be selected to be displayed in the title field of milestone cards in week
// view. The field has to have the right type (see filterTitleFieldTypes). In addition, it needs to exist in all the
// milestone sets linked to this visual board. These sets may contain different fields, as each milestone set comes from
// an active folder, and these folders may be defined differently. The user can only choose fields that are present in
// all milestones. However, there is a default value to "display the milestone's entry title", even though each
// milestone folder may have different entry title fields. This is represented by the DISPLAY_ENTRY_TITLE constant, and
// means that the contents of the title field will be determined later, individually for each milestone displayed.
export const getEligibleMilestoneTitleFields = board => {
  let eligibleFields;
  let setsToCheck;

  if (board.milestones_config.length <= 0) {
    // The milestone has no fields. Only display the default value (added below).
    eligibleFields = [];
  } else if (board.milestones_config.length === 1) {
    // There is only one milestone set. Display all of its fields (provided they are the right type), in addition to the
    // default value.
    eligibleFields = filterTitleFieldTypes(board.milestones_config[0].fields);
  } else {
    // There are several milestone sets. Loop through the first milestone set's fields, and include any field that is
    // present in all the other milestone sets. The field must have both the same name and the same type.
    eligibleFields = filterTitleFieldTypes(board.milestones_config[0].fields);
    // We need to check all sets except the first.
    setsToCheck = board.milestones_config.slice(1);
    eligibleFields = eligibleFields.filter(candidateField => {
      return setsToCheck.every(milestoneSet => containsField(milestoneSet.fields, candidateField));
    });
  }

  // Add the default "display the milestone's entry title" option at the top of the list.
  return [
    {
      id: DISPLAY_ENTRY_TITLE,
      name: intl.get('board.toolbar.display_entry_title'),
    },
  ].concat(eligibleFields);
};

// Return a list of field objects that can be selected to be displayed in the title field of question cards in week
// view. The field has to have the right type (see filterTitleFieldTypes).
function getEligibleQuestionTitleFields(board) {
  return filterTitleFieldTypes(board.field_config.fields);
}

function getEligibleDeliveryTitleFields(board) {
  return board.delivery_config
    ? filterTitleFieldTypes(board.delivery_config.fields)
    : // to make deliveries and milestones options consistent on empty list
      [
        {
          id: DISPLAY_ENTRY_TITLE,
          name: intl.get('board.toolbar.display_entry_title'),
        },
      ];
}

function BoardCellConfig({ board, classes, viewConfig, viewName, onChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = event => {
    onChange(viewConfig.set(event.target.name, event.target.value));
  };

  const toggleCheckbox = event => {
    onChange(viewConfig.set(event.target.name, event.target.checked));
  };

  const milestoneTitleFields = getEligibleMilestoneTitleFields(board);
  const questionTitleFields = getEligibleQuestionTitleFields(board);
  const deliveryTitleFields = getEligibleDeliveryTitleFields(board);
  const milestoneTitleField = viewConfig.milestoneTitle || DISPLAY_ENTRY_TITLE;
  const questionTitleField = viewConfig.questionTitle || getEntryTitle(board.field_config.fields);
  const deliveryTitleField = board.delivery_config
    ? viewConfig.deliveryTitle || getEntryTitle(board.delivery_config.fields)
    : DISPLAY_ENTRY_TITLE;

  const settingsAdjusted =
    (viewName === WEEK_BOARD_VIEW_MODE &&
      (!viewConfig.showQuestions || !viewConfig.showDeliveries)) ||
    !viewConfig.showAttachments ||
    !viewConfig.showComments;

  return (
    <React.Fragment>
      <Tooltip title={intl.get('board.toolbar.settings.tooltip')}>
        <IconButton onClick={handleClick}>
          <SettingsIcon className={classNames(settingsAdjusted && classes.settingsAdjusted)} />
        </IconButton>
      </Tooltip>
      <Menu
        classes={{ paper: classes.menu }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <ListSubheader style={{ outline: 'none' }}>
          {intl.get('board.toolbar.settings')}
        </ListSubheader>
        {viewName === WEEK_BOARD_VIEW_MODE && (
          <span>
            <MenuItem className={classes.field}>
              <FormControl style={{ minWidth: 250 }}>
                <InputLabel htmlFor="age-label-placeholder">
                  {intl.get('board.toolbar.milestone_title')}
                </InputLabel>
                <Select
                  value={milestoneTitleField}
                  onChange={handleChange}
                  inputProps={{
                    name: 'milestoneTitle',
                    id: 'milestoneTitle-label-placeholder',
                  }}>
                  {milestoneTitleFields.map(field => (
                    <MenuItem key={`mt-title-${field.id}`} value={field.id}>
                      {field.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MenuItem>
            <MenuItem className={classes.field}>
              <FormControl style={{ minWidth: 250 }}>
                <InputLabel htmlFor="age-label-placeholder">
                  {intl.get('board.toolbar.delivery_title')}
                </InputLabel>
                <Select
                  value={deliveryTitleField}
                  onChange={handleChange}
                  inputProps={{
                    name: 'deliveryTitle',
                    id: 'deliveryTitle-label-placeholder',
                  }}>
                  {deliveryTitleFields.map(field => (
                    <MenuItem key={`dt-title-${field.id}`} value={field.id}>
                      {field.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MenuItem>
          </span>
        )}
        <MenuItem className={classes.field}>
          <FormControl style={{ minWidth: 250 }}>
            <InputLabel htmlFor="age-label-placeholder">
              {intl.get('board.toolbar.question_title')}
            </InputLabel>
            <Select
              value={questionTitleField}
              onChange={handleChange}
              inputProps={{
                name: 'questionTitle',
                id: 'questionTitle-label-placeholder',
              }}>
              {questionTitleFields.map(field => (
                <MenuItem key={`qt-title-${field.id}`} value={field.id}>
                  {field.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MenuItem>
        {viewName === WEEK_BOARD_VIEW_MODE && (
          <MenuItem className={classes.field}>
            <FormControlLabel
              control={
                <Switch
                  name="showQuestions"
                  checked={viewConfig.showQuestions}
                  onChange={toggleCheckbox}
                  value="true"
                />
              }
              label={intl.get('board.toolbar.show_questions')}
            />
          </MenuItem>
        )}
        {viewName === WEEK_BOARD_VIEW_MODE && (
          <MenuItem className={classes.field}>
            <FormControlLabel
              control={
                <Switch
                  name="showDeliveries"
                  checked={viewConfig.showDeliveries}
                  onChange={toggleCheckbox}
                  value="true"
                />
              }
              label={intl.get('board.toolbar.show_deliveries')}
            />
          </MenuItem>
        )}
        <MenuItem className={classes.field}>
          <FormControlLabel
            control={
              <Switch
                name="showAttachments"
                checked={viewConfig.showAttachments}
                onChange={toggleCheckbox}
                value="true"
              />
            }
            label={intl.get('board.toolbar.show_attachments')}
          />
        </MenuItem>
        <MenuItem className={classes.field}>
          <FormControlLabel
            control={
              <Switch
                name="showComments"
                checked={viewConfig.showComments}
                onChange={toggleCheckbox}
                value="true"
              />
            }
            label={intl.get('board.toolbar.show_comments')}
          />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

BoardCellConfig.propTypes = {
  board: PropTypes.shape({
    field_config: PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    delivery_config: PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  classes: PropTypes.shape({
    field: PropTypes.string,
    settingsAdjusted: PropTypes.string,
    menu: PropTypes.string,
  }).isRequired,
  viewConfig: PropTypes.shape({
    set: PropTypes.func,
    milestoneTitle: PropTypes.string,
    questionTitle: PropTypes.string,
    deliveryTitle: PropTypes.string,
    showQuestions: PropTypes.bool,
    showDeliveries: PropTypes.bool,
    showAttachments: PropTypes.bool,
    showComments: PropTypes.bool,
  }).isRequired,
  viewName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

BoardCellConfig.defaultProps = {
  viewName: '',
};

export default withStyles(styles)(BoardCellConfig);
