import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withStyles } from '@material-ui/core/styles';
import CardItem from './CardItem';
import { STATUS_RED, STATUS_GREEN } from './CardItem';
import { milestoneStatusMessages } from '../../utils/MilestoneUtil';
import { MILESTONE } from '../../utils/Constants';
import MilestoneIcon from '../../common/icons/MilestoneIcon';
import { geMilestoneFieldValuesToDisplayInTooltip } from '../../utils/FieldUtil';

const styles = () => ({
  typeIcon: {
    height: 32,
    width: 32,
    color: '#d64c48',
  },
  typeIconHighReadability: {
    height: 48,
    width: 48,
    color: '#d64c48',
  },
});

function isMilestoneMet(card) {
  return !!card.metDate;
}

const MilestoneCardItem = ({
  classes,
  card,
  board,
  viewConfig,
  onCardClick,
  rowIndex = 0,
  columnIndex = 0,
  type,
}) => {
  const isMet = isMilestoneMet(card);

  return (
    <CardItem
      key={`card-${card.id}`}
      card={card}
      board={board}
      viewConfig={viewConfig}
      onCardClick={card => {
        onCardClick({ card, type: MILESTONE });
      }}
      getFieldMap={geMilestoneFieldValuesToDisplayInTooltip}
      typeIcon={
        <MilestoneIcon
          className={
            viewConfig.highReadability ? classes.typeIconHighReadability : classes.typeIcon
          }
        />
      }
      typeIconTooltip={intl.get('board.tooltips.milestone')}
      statusColour={isMet ? STATUS_GREEN : STATUS_RED}
      statusTooltip={intl.get(milestoneStatusMessages[card.status])}
      titleField="milestoneTitle"
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      type={type}
    />
  );
};

MilestoneCardItem.propTypes = {
  classes: PropTypes.shape({
    typeIconHighReadability: PropTypes.string,
    typeIcon: PropTypes.string,
  }).isRequired,
  card: PropTypes.object.isRequired,
  board: PropTypes.object.isRequired,
  viewConfig: PropTypes.object.isRequired,
  onCardClick: PropTypes.func,
  rowIndex: PropTypes.number,
  columnIndex: PropTypes.number,
  type: PropTypes.string.isRequired,
};

MilestoneCardItem.defaultProps = {
  onCardClick: () => {},
  rowIndex: 0,
  columnIndex: 0,
};

export default withStyles(styles)(MilestoneCardItem);
