import React from 'react';
import PropTypes from 'prop-types';

import { FilePicker as InteraxoFilePicker } from '@tribiahq/interaxo-file-picker';

import { isAuth0 } from '../../utils/AuthUtil';
import { ENVIRONMENTAL_VARIABLES } from '../../utils/Environment';
import { useAuth0 } from '../../common/auth0';
import { useKeycloak } from '../../common/keycloak';

const FilePicker = ({
  lazyInit,
  styles,
  buttonStyles,
  buttonText,
  cancelButtonStyles,
  cancelButtonText,
  restrictToCommunity,
  defaultRoom,
  callback,
}) => {
  const useAuth = isAuth0() ? useAuth0 : useKeycloak;
  const { getTokenSilently } = useAuth();

  return (
    <InteraxoFilePicker
      getToken={getTokenSilently}
      lazyInit={lazyInit}
      key={new Date().getTime()} // Needed to destroy old instance when closing and re-opening
      styles={styles}
      buttonText={buttonText}
      buttonStyles={buttonStyles}
      cancelButtonText={cancelButtonText}
      cancelButtonStyles={cancelButtonStyles}
      restrictToCommunity={restrictToCommunity}
      defaultRoom={defaultRoom}
      apiUrl={ENVIRONMENTAL_VARIABLES.PUBLIC_API_URL}
      ixUrl={ENVIRONMENTAL_VARIABLES.INTERAXO_URL}
      callback={callback}
    />
  );
};

FilePicker.propTypes = {
  lazyInit: PropTypes.bool,
  styles: PropTypes.shape({}),
  buttonStyles: PropTypes.shape({}),
  buttonText: PropTypes.string,
  cancelButtonStyles: PropTypes.shape({}),
  cancelButtonText: PropTypes.string,
  restrictToCommunity: PropTypes.bool,
  defaultRoom: PropTypes.string,
  callback: PropTypes.func.isRequired,
};

FilePicker.defaultProps = {
  lazyInit: false,
  restrictToCommunity: false,
};

export default FilePicker;
