import React from 'react';
import intl from 'react-intl-universal';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const styles = () => ({
  button: {
    marginLeft: 10,
  },
});

const DeleteCardItemDialog = ({ onDelete, onClose, classes, titles, disabled, ...other }) => {
  const cancelDelete = () => {
    onClose();
  };

  const continueDelete = () => {
    onDelete();
  };

  return (
    !disabled && (
      <Dialog onClose={cancelDelete} classes={{ paper: classes.dialog }} {...other}>
        <DialogTitle>{titles.deleteDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{titles.deleteDialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="primary">
            {intl.get('common.dialog.cancel')}
          </Button>
          <Button onClick={continueDelete} color="secondary">
            {intl.get('common.dialog.delete')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

DeleteCardItemDialog.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    dialog: PropTypes.string,
  }),
  titles: PropTypes.shape({
    deleteDialogTitle: PropTypes.string,
    deleteDialogMessage: PropTypes.string,
  }),
};

DeleteCardItemDialog.defaultProps = {
  onClose: () => {
    /*do nothing*/
  },
  disabled: false,
  classes: {},
  titles: {},
};

export default withStyles(styles)(DeleteCardItemDialog);
