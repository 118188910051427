import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  root: {
    maxWidth: theme.spacing(25),
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    position: 'relative',
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderRadius: '4px',
    minHeight: theme.spacing(2),
    '&:before': {
      content: '" "',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      zIndex: '-1',
      borderRadius: '4px',
      background: theme.palette.primary.main,
      transformOrigin: 'bottom left',
      transform: 'skew(-30deg, 0deg)',
    },
  },
});

const Tab = ({ classes, children }) => {
  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.content}>
        {children}
      </Typography>
    </div>
  );
};

Tab.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(Tab);
