import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import { default as Editor } from '../../common/richtext';

import { getSelectFieldOptions, getInputProps } from '../../utils/FieldUtil';
import SubscriptionContainer from '../../common/SubscriptionContainer';
import { validateRequiredFields, validateEntryTitleField } from '../../utils/Validators';
import { renderField, renderSelectField, renderSwitchField } from '../../utils/RenderUtil';
import DatePicker from '../../common/DatePicker';
import StopClickPropagation from '../../common/StopClickPropagation';
import Value from '../../common/Value';
import SplitButton from '../../common/SplitButton';

const styles = theme => ({
  field: {
    width: 540,
  },
  textField: {
    width: 540,
    marginBottom: 15,
  },
  dateField: {
    width: 300,
    marginBottom: 15,
  },
  listField: {
    width: 300,
    marginBottom: 15,
  },
  listItem: {
    minHeight: 36,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  metaInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '70%',
  },
  column: {
    verticalAlign: 'bottom',
    marginLeft: '2%',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

const Form = ({
  formRef,
  classes,
  theme,
  restrictions = {
    'task-done': () => ({}),
    'task-responsible': () => ({}),
    'rich-text': () => ({}),
    'auto-number': () => ({}),
    'unique-document-id': () => ({}),
    'sequence-number': () => ({}),
    member: () => ({}),
    list: () => ({}),
    date: () => ({}),
    string: () => ({}),
    numeric: () => ({}),
  },
  formValidation = {
    answered_date_field: () => ({}),
  },
  type,
  onDelete = () => {},
  onCancel = () => {},
  onFetchPossibleResponsible = () => {},
  onSubmit = () => {},
  item,
  possibleResponsible = [],
  requiredFields,
  initialValues,
  disabledFields,
  fieldConfig,
  deleteIcon,
  metaInfo = null,
  submitOptions = [],
}) => {
  // eslint-disable-next-line no-unused-vars
  const [initializedEditors, setInitializedEditors] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [closeOnSubmit, setCloseOnSubmit] = useState(false);
  const [editorStates, setEditorStates] = useState({});

  const handleFocus = useCallback(id => {
    setEditorStates(prev => ({ ...prev, [id]: true }));
  }, []);

  const validate = useCallback(
    values => {
      let errors = {};
      validateRequiredFields({ requiredFields, values, errors });
      validateEntryTitleField({ fieldConfig, values, errors });
      return errors;
    },
    [requiredFields, fieldConfig],
  );

  const getFieldDef = useCallback(({ fieldConfig, fieldId }) => {
    // eslint-disable-next-line react/prop-types
    return fieldConfig.fields.filter(def => def.id === fieldId)[0];
  }, []);

  const taskDone = useCallback(
    ({ field, values, disabled, handleChange, handleBlur, getFieldError }) => {
      return [
        renderSwitchField({
          name: field.id,
          label: field.name,
          value: values[field.id],
          disabled,
          handleChange,
          handleBlur,
          error: getFieldError({ id: field.id }),
        }),
      ];
    },
    [],
  );

  const taskResponsible = useCallback(
    ({ field, values, disabled, handleChange, handleBlur, getFieldError }) => {
      return [
        renderSelectField({
          name: field.id,
          label: field.name,
          value: values[field.id] || '',
          disabled: possibleResponsible ? disabled || possibleResponsible.length === 0 : disabled,
          handleChange,
          handleBlur,
          error: getFieldError({ id: field.id }),
          options: [''].concat(possibleResponsible || []),
          className: classes.listField,
          itemStyle: classes.listItem,
        }),
      ];
    },
    [possibleResponsible, classes.listField, classes.listItem],
  );

  const listField = useCallback(
    ({ field, values, disabled, handleChange, handleBlur, getFieldError, setFieldValue }) => {
      return [
        renderSelectField({
          name: field.id,
          label: field.name,
          value: values[field.id] || '',
          disabled,
          handleChange: e => {
            if (onFetchPossibleResponsible && field.id === fieldConfig.to_member_field) {
              onFetchPossibleResponsible({
                disciplines: e.target.value,
              });
              setFieldValue('phentry:taskResponsible', []);
            }
            handleChange(e);
          },
          handleBlur,
          error: getFieldError({ id: field.id }),
          options: getSelectFieldOptions({
            field,
            fieldConfig,
            values,
          }),
          className: classes.listField,
          itemStyle: classes.listItem,
          multiple: getFieldDef({
            fieldConfig,
            fieldId: field.id,
          }).multiple,
        }),
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onFetchPossibleResponsible, fieldConfig, getFieldDef],
  );

  const dateField = useCallback(
    ({ field, values, disabled, getFieldError, setFieldValue, disablePast, disableFuture }) => {
      return [
        <DatePicker
          key={`field-${field.id}`}
          field={field}
          value={values[field.id]}
          handleChange={setFieldValue}
          disabled={disabled}
          error={getFieldError({ id: field.id })}
          disablePast={disablePast}
          disableFuture={disableFuture}
          className={classes.dateField}
        />,
      ];
    },
    [classes.dateField],
  );

  const richTextField = useCallback(
    ({ field, values, disabled, handleBlur, getFieldError, setFieldValue }) => {
      const id = field.id;
      return [
        <Editor
          id={id}
          label={field.name}
          includeLabel
          value={values[id]}
          errorText={getFieldError({ id: field.id })}
          style={{ marginTop: 0, marginBottom: 0 }}
          width={540}
          height={editorStates[id] ? 280 : 160}
          onChange={(event, editor) => {
            if (!Object.prototype.hasOwnProperty.call(initializedEditors, field.id)) {
              setEditorStates({ ...editorStates, [field.id]: true });
            } else {
              setFieldValue(id, editor.getContent());
            }
          }}
          disabled={disabled}
          onFocus={() => {
            handleFocus(id);
          }}
          key={`field-${id}-RTE`}
        />,
      ];
    },
    [handleFocus, editorStates, initializedEditors],
  );

  const defaultField = useCallback(
    ({ field, values, disabled, handleChange, handleBlur, getFieldError, touched, ...other }) => {
      return renderField({
        name: field.id,
        label: field.name,
        value: values[field.id],
        disabled: disabled || field.type === 'auto-number',
        handleChange,
        handleBlur,
        error: getFieldError({ id: field.id }),
        className: classes.textField,
        ...getInputProps({
          field,
          fieldConfig,
          values,
        }),
        ...other,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classes.textField, getInputProps, fieldConfig],
  );

  const renderFieldMap = {
    'task-done': taskDone,
    'task-responsible': taskResponsible,
    'rich-text': richTextField,
    'auto-number': defaultField,
    'unique-document-id': defaultField,
    'sequence-number': defaultField,
    member: listField,
    list: listField,
    date: dateField,
    string: defaultField,
    numeric: defaultField,
  };

  const isFieldDisabled = useCallback(
    (disabledFields, values, fieldId) => {
      let key = Object.keys(formValidation).find(key => fieldConfig[key] === fieldId);
      return key
        ? disabledFields.includes(fieldId) || !formValidation[key](fieldConfig, values)
        : disabledFields.includes(fieldId);
    },
    [fieldConfig, formValidation],
  );

  const buildFormItems = useCallback(
    ({
      item,
      errors,
      values,
      disabledFields,
      handleChange,
      handleBlur,
      touched,
      setFieldValue,
    }) => {
      // eslint-disable-next-line react/prop-types
      let fields = item.fields || [];
      if (!item.id) {
        fields = fields.filter(field => field.type !== 'task-done');
      }

      if ('QUESTION' === type) {
        const getFieldError = ({ id }) => errors[id];
        return (
          <div key={`f-${item.id}`} className={classes.container}>
            {fields.map(field =>
              renderFieldMap[field.type]({
                field,
                values,
                disabled: isFieldDisabled(disabledFields, values, field.id),
                handleChange,
                handleBlur,
                getFieldError,
                touched,
                setFieldValue,
                ...restrictions[field.type](fieldConfig, field),
              }),
            )}
          </div>
        );
      }
      return null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, classes, restrictions, fieldConfig, isFieldDisabled, renderFieldMap],
  );

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={async values => {
        await onSubmit({
          values,
          item,
          closeOnSuccess: closeOnSubmit,
        });
      }}
      validate={validate}
      enableReinitialize={true}>
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        isValid,
        dirty,
      }) => (
        <form onSubmit={handleSubmit}>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes.header}>
                <Typography className={classes.heading}>{intl.get('common.form.info')}</Typography>
                <StopClickPropagation>
                  {deleteIcon}
                  {item.id && <SubscriptionContainer id={item.id} />}
                </StopClickPropagation>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {buildFormItems({
                item,
                disabledFields,
                errors,
                values,
                handleChange,
                handleBlur,
                touched,
                setFieldValue,
              })}
            </ExpansionPanelDetails>
            <Divider />
            <ExpansionPanelActions
              style={{
                justifyContent: metaInfo ? 'space-between' : 'flex-end',
              }}>
              {metaInfo && (
                <div className={classes.metaInfo}>
                  <div className={classes.column}>
                    <Value
                      caption={intl.get('card.owner')}
                      value={metaInfo.owner}
                      classes={{ caption: classes.title }}
                    />
                  </div>
                  <div className={classes.column}>
                    <Value
                      caption={intl.get('card.created.by')}
                      value={metaInfo.createdBy}
                      classes={{ caption: classes.title }}
                    />
                    <Value
                      caption={intl.get('card.created')}
                      value={metaInfo.creationDate}
                      classes={{ caption: classes.title }}
                    />
                  </div>
                  <div className={classes.column}>
                    <Value
                      caption={intl.get('card.changed.by')}
                      value={metaInfo.modifiedBy}
                      classes={{ caption: classes.title }}
                    />
                    <Value
                      caption={intl.get('card.changed')}
                      value={metaInfo.modifiedDate}
                      classes={{ caption: classes.title }}
                    />
                  </div>
                </div>
              )}
              <div style={{ display: 'flex' }}>
                {!isSubmitting && (
                  <Button size="small" onClick={onCancel}>
                    {intl.get('common.form.back')}
                  </Button>
                )}
                <SplitButton
                  disabled={!isValid || !dirty || isSubmitting}
                  isLoading={isSubmitting}
                  options={submitOptions}
                />
              </div>
            </ExpansionPanelActions>
          </ExpansionPanel>
        </form>
      )}
    </Formik>
  );
};

Form.propTypes = {
  formRef: PropTypes.node,
  classes: PropTypes.shape({
    field: PropTypes.string,
    textField: PropTypes.string,
    dateField: PropTypes.string,
    listField: PropTypes.string,
    container: PropTypes.string,
    header: PropTypes.string,
    heading: PropTypes.string,
    metaInfo: PropTypes.string,
    column: PropTypes.string,
    title: PropTypes.string,
    listItem: PropTypes.string,
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
  restrictions: PropTypes.shape({}),
  formValidation: PropTypes.shape({}),
  type: PropTypes.string,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  onOpenDialog: PropTypes.func,
  onFetchPossibleResponsible: PropTypes.func,
  onSubmit: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.string,
    owner: PropTypes.string,
  }).isRequired,
  possibleResponsible: PropTypes.arrayOf(PropTypes.shape({})),
  requiredFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  disabledFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  fieldConfig: PropTypes.shape({
    to_member_field: PropTypes.string.isRequired,
  }),
  deleteIcon: PropTypes.node,
  metaInfo: PropTypes.shape({
    owner: PropTypes.string,
    creationDate: PropTypes.string,
    createdBy: PropTypes.string,
    modifiedDate: PropTypes.string,
    modifiedBy: PropTypes.string,
  }),
  submitOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

Form.defaultProps = {
  restrictions: {
    'task-done': () => ({}),
    'task-responsible': () => ({}),
    'rich-text': () => ({}),
    'auto-number': () => ({}),
    'unique-document-id': () => ({}),
    'sequence-number': () => ({}),
    member: () => ({}),
    list: () => ({}),
    date: () => ({}),
    string: () => ({}),
    numeric: () => ({}),
  },
  formValidation: {
    answered_date_field: () => ({}),
  },
  onDelete: () => {},
  onCancel: () => {},
  onFetchPossibleResponsible: () => {},
  onSubmit: () => {},
  possibleResponsible: [],
  metaInfo: null,
  submitOptions: [],
};

export default withStyles(styles, { withTheme: true })(Form);
