import 'react-app-polyfill/ie11'; // For IE 11 support

import React from 'react';
import { render } from 'react-dom';
import { Auth0Provider } from './common/auth0';
import { KeycloakProvider } from './common/keycloak';

import Authentication from './Authentication';
import './index.css';
import { unregister } from './registerServiceWorker';
import { isAuth0 } from './utils/AuthUtil';
import { AUTH0_CONFIG, KEYCLOAK_CONFIG } from './services/AuthConfig';

window.appInfo = {
  version: process.env.REACT_APP_VERSION,
  sha: process.env.REACT_APP_GIT_SHA,
};

const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname,
  );
};

render(
  isAuth0() ? (
    <Auth0Provider
      audience={AUTH0_CONFIG.audience}
      domain={AUTH0_CONFIG.domain}
      client_id={AUTH0_CONFIG.clientId}
      scope={AUTH0_CONFIG.scope}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}>
      <Authentication />
    </Auth0Provider>
  ) : (
    <KeycloakProvider
      url={KEYCLOAK_CONFIG.url}
      client_id={KEYCLOAK_CONFIG.clientId}
      realm={KEYCLOAK_CONFIG.realm}
      scope={KEYCLOAK_CONFIG.scope}
      redirect_uri={window.location.href}>
      <Authentication />
    </KeycloakProvider>
  ),
  document.getElementById('root'),
);

// Add offline worker
unregister();
