import React from 'react';
import PropTypes from 'prop-types';

import Fab from '@material-ui/core/Fab';
import IconCreate from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';

const AddCardButton = ({ onClick, title }) => {
  return (
    <Tooltip title={title}>
      <Fab
        id={'add-card'}
        size="small"
        style={{ marginRight: 20 }}
        color="primary"
        onClick={onClick}>
        <IconCreate />
      </Fab>
    </Tooltip>
  );
};

AddCardButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default AddCardButton;
