import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';

import { alertAction } from '../thunks/Alerts';
import { selectUserProfile } from '../ducks/Auth';
import { selectActiveBoard } from '../ducks/Boards';
import { selectSubscriptions } from '../ducks/Subscribers';
import * as subscriberActions from '../thunks/Subscribers';
import Subscription from './Subscription';

const mapStateToProps = () =>
  createStructuredSelector({
    user: selectUserProfile(),
    activeBoard: selectActiveBoard(),
    subscriptions: selectSubscriptions(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...subscriberActions,
    },
    dispatch,
  ),
});

const SubscriptionContainer = ({ id, activeBoard, user, subscriptions, actions }) => {
  useEffect(() => {
    if (id) {
      fetchSubscriptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const isSubscribing = () => {
    return (
      subscriptions &&
      subscriptions.some(
        subscription => subscription.id === user.id.split('@')[0].replace('~', '@'),
      )
    );
  };

  const fetchSubscriptions = () => {
    actions.fetchSubscriptions({ boardId: activeBoard.id, id }).then(action =>
      alertAction({
        action,
        error: intl.get('subscription.fetch.error'),
      }),
    );
  };

  const handleSubscribe = () => {
    const subscribing = isSubscribing();

    if (subscribing) {
      actions.unsubscribe({ boardId: activeBoard.id, id }).then(action =>
        alertAction({
          action,
          error: intl.get('subscription.unsubscribe.error'),
          onSuccess: () => fetchSubscriptions(),
        }),
      );
    } else {
      actions.subscribe({ boardId: activeBoard.id, id }).then(action =>
        alertAction({
          action,
          success: intl.get('subscription.subscribe.success'),
          error: intl.get('subscription.subscribe.error'),
          onSuccess: () => fetchSubscriptions(),
        }),
      );
    }
  };

  if (!id) {
    return <div />;
  }

  return <Subscription id={id} isSubscribing={isSubscribing()} onSubscribe={handleSubscribe} />;
};

SubscriptionContainer.propTypes = {
  activeBoard: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  subscriptions: PropTypes.array.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    unsubscribe: PropTypes.func,
    subscribe: PropTypes.func,
    fetchSubscriptions: PropTypes.func,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionContainer);
