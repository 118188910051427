import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import intl from 'react-intl-universal';

import { getISOWeek, format, startOfDay, getISOWeekYear } from 'date-fns';
import red from '@material-ui/core/colors/red';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';
import { getBeginAndEndOfWeek } from '../../utils/DateUtil';
import {
  weekBoardDefaultColumnIds,
  extractDeliveries,
  extractQuestions,
  calculatePPC,
  MONDAY,
} from '../../utils/FieldUtil';
import { getLocale } from '../../utils/LocaleUtil';
import { getWeekBoardStructure } from '../../utils/WeekBoardUtil';
import { ANSWERED_ROW } from '../../utils/Constants';

import {
  canDropMilestoneOnCell,
  canDropDeliveryOnCell,
  canDropQuestionOnCell,
} from '../../utils/DNDUtils';
import {
  DELIVERY,
  MILESTONE,
  DISCIPLINABLE_CELL,
  MILESTONE_CELL,
  HIGH_READABILITY_CELL_WIDTH,
  DEFAULT_CELL_WIDTH,
} from '../../utils/Constants';

import Cell from './Cell';
import AnsweredCardsList from './AnsweredCardsList';
import Tooltip from '@material-ui/core/Tooltip';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PPCProgress from '../common/PPCProgressBar';

const styles = {
  dot: {
    height: 25,
    width: 25,
    borderRadius: '50%',
    display: 'inline-block',
  },
  labelCellRoot: {
    backgroundColor: '#f3f3f3',
  },
  labelCellRootWeekEnd: {
    backgroundColor: '#FFEEEE',
  },
  labelCellRootWeekDay: {
    backgroundColor: '#EEFFEE',
  },
  labelCellRootOdd: {
    backgroundColor: '#DEDEDE',
  },
  labelCell: {
    wordWrap: 'break-word',
  },
  milestoneContent: {
    padding: 5,
    paddingTop: 25,
  },
  weekHeader: {
    height: 65,
    display: 'flex',
    alignItems: 'center',
    borderLeft: '1px solid lightgrey',
    justifyContent: 'center',
  },
  weekHeaderHighContrast: {
    height: 65,
    display: 'flex',
    alignItems: 'center',
    borderLeft: '1px solid lightgrey',
    justifyContent: 'center',
  },
  icon: {
    height: 16,
    width: 16,
  },
  iconHighContrast: {
    height: 32,
    width: 32,
  },
  weekLabel: {
    fontSize: 14,
  },
  weekLabelHighContrast: {
    fontSize: 24,
  },
  week: {
    backgroundColor: 'transparent',
    color: 'black',
  },
  currentWeek: {
    backgroundColor: '#3eb1c8',
  },
  currentWeekHighReadability: {
    backgroundColor: '#000000',
  },
  dayColumn: {
    backgroundColor: 'transparent',
    color: 'black',
  },
  currentWeekDayColumn: {
    backgroundColor: 'transparent',
    color: 'black',
  },
  overdueWeek: {
    backgroundColor: red[300],
  },
  overdue: {
    color: red[300],
  },
  milestoneCell: {
    flexDirection: 'column',
    maxWidth: 200,
    height: 300,
    borderRadius: 0,
    backgroundColor: '#EEEEEE',
    display: 'flex',
    justifyContent: 'center',
  },
  rootOdd: {
    backgroundColor: '#DEDEDE',
  },
  rootWeekEnd: {
    backgroundColor: '#FFEEEE',
  },
  rootWeekDay: {
    backgroundColor: '#EEFFEE',
  },
  root: {
    backgroundColor: '#EEEEEE',
  },
  dateLabel: {
    display: 'inline-block',
    height: 10,
    lineHeight: '1.0rem',
  },
  expandCollapseIcon: {
    cursor: 'pointer',
    width: 24,
    height: 24,
    padding: 0,
  },
  truncated: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '167px',
  },
  cellRoot: {
    padding: '0px 0px 0px 0px',
    textAlign: 'center',
    '&:last-child': {
      paddingRight: 0,
    },
  },
  header: {
    fontSize: 14,
  },
  headerHighContrast: {
    fontSize: 24,
    fontWeight: 'bold',
    marginLeft: 10,
    marginTop: 5,
  },
  itemHeaderHighContrast: {
    height: 50,
    cursor: 'pointer',
  },
  itemHeader: {
    height: 10,
    cursor: 'pointer',
  },
  itemHeaderCollapsed: {
    borderBottom: '1px solid lightgrey',
  },
  selected: {
    backgroundColor: '#EAEAEA',
  },
  progressContainer: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateColumn: { width: '100%', display: 'flex', flexDirection: 'column' },
  dateColumnContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  PPCProgress: { display: 'inline-block', height: 5 },
  yColumnHeader: {
    padding: '6px 0 0 10px',
  },
  rootHeader: {
    marginTop: 0,
    position: 'relative',
  },
};

let locale;

const ANSWERED_LIST_ID = 'answered cards list';

const WeekBoard = props => {
  const {
    cards,
    onExpandCollapseColumn,
    onUpdateCard,
    onChange,
    milestones,
    deliveries,
    width,
    board,
    classes,
    viewConfig,
    config,
    onSetScrollPos,
    scrollPos,
  } = props;

  const tableWrapperRef = useRef();

  useEffect(() => {
    const tableWrapper = tableWrapperRef.current;

    if (scrollPos.scrollY && scrollPos.scrollY > 0) {
      tableWrapper.scrollTop = scrollPos.scrollY;
    }

    if (scrollPos.scrollX && scrollPos.scrollX > 0) {
      tableWrapper.scrollLeft = scrollPos.scrollX;
    }

    window.addEventListener('scroll', handleScroll, true);

    return () => {
      const scrollTop = tableWrapper.scrollTop;
      const scrollLeft = tableWrapper.scrollLeft;
      onSetScrollPos({
        pos: { scrollY: scrollTop, scrollX: scrollLeft },
      });
      window.removeEventListener('scroll', handleScroll, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = event => {
    const lastScrollX = Math.max(0, event.target.scrollLeft);
    if (event.target.scrollTop === 0) {
      window.requestAnimationFrame(() => {
        for (let i = 0; i < config.xColumns.length; i++) {
          const row = document.getElementById(`col-${i}`);
          if (row) {
            row.style.left = `${lastScrollX}px`;
          }
        }
      });
    }
  };

  const handleUpdateCard = ({ card, rowIndex, columnIndex, sourceRowIndex, sourceColumnIndex }) => {
    onUpdateCard({
      card,
      rowIndex,
      columnIndex,
      sourceRowIndex,
      sourceColumnIndex,
      config,
    });
  };

  const handleClick = id => {
    const expanded = viewConfig.expandedWeekRows.indexOf(id) > -1;

    if (expanded) {
      onChange(
        viewConfig.set(
          'expandedWeekRows',
          viewConfig.expandedWeekRows.filter(item => item !== id),
        ),
      );
    } else {
      onChange(viewConfig.set('expandedWeekRows', viewConfig.expandedWeekRows.concat(id)));
    }
  };

  const handleExpandCell = ({ key }) => {
    onChange(viewConfig.set('expandedWeekCells', viewConfig.expandedWeekCells.concat(key)));
  };

  const handleCollapseCell = ({ key }) => {
    onChange(
      viewConfig.set(
        'expandedWeekCells',
        viewConfig.expandedWeekCells.filter(item => {
          return item !== key;
        }),
      ),
    );
  };

  const canDropOnCell = ({ card, rowIndex, columnIndex, sourceRowIndex, sourceColumnIndex }) => {
    if (card.type === MILESTONE) {
      return canDropMilestoneOnCell({
        card,
        columnIndex,
        rowIndex,
        sourceRowIndex,
        sourceColumnIndex,
      });
    }
    if (card.type === DELIVERY) {
      return canDropDeliveryOnCell({
        card,
        board,
        columnIndex,
        rowIndex,
        sourceRowIndex,
        sourceColumnIndex,
        config,
      });
    }
    // if QUESTION
    return canDropQuestionOnCell({
      card,
      board,
      columnIndex,
      rowIndex,
      sourceRowIndex,
      sourceColumnIndex,
      config,
    });
  };

  const getCellClassName = ({ columnIndex, rowIndex, config }) => {
    const isHeaderCell = rowIndex === 0 || rowIndex === config.xColumns.length - 1;

    // eslint-disable-next-line react/prop-types
    if (config.yColumns[columnIndex].dayColumn && config.yColumns[columnIndex].weekend) {
      return isHeaderCell ? classes.labelCellRootWeekEnd : classes.rootWeekEnd;
    }

    // eslint-disable-next-line react/prop-types
    if (config.yColumns[columnIndex].dayColumn && !config.yColumns[columnIndex].weekend) {
      return isHeaderCell ? classes.labelCellRootWeekDay : classes.rootWeekDay;
    }

    return isHeaderCell ? classes.labelCellRoot : classes.root;
  };

  const getColumnClassName = ({ weekColumnId }) => {
    const { classes, viewConfig } = props;
    let isDayColumn = weekColumnId.indexOf('-') > -1;
    const weekNumber = getISOWeek(Date.now());
    const year = getISOWeekYear(Date.now(), { weekStartsOn: MONDAY });
    let isCurrentWeek =
      weekColumnId.indexOf(getISOWeek(Date.now()) + '-') > -1 ||
      weekColumnId === `${weekNumber}:${year}`;

    if (isCurrentWeek && isDayColumn) {
      return classes.currentWeekDayColumn;
    }

    if (isCurrentWeek) {
      return viewConfig.highReadability ? classes.currentWeekHighReadability : classes.currentWeek;
    }

    if (isDayColumn) {
      return classes.dayColumn;
    }

    return classes.week;
  };

  const getYHeader = ({ index }) => {
    const { config } = props;
    let weekColumnId = config.yColumns[index].id;
    const week = config.yColumns[index].weekNumber;
    const year = config.yColumns[index].year;
    const handleClick = () => {
      let weekNumber = config.yColumns[index].weekNumber;
      if (weekNumber !== undefined) {
        onExpandCollapseColumn({ weekColumnId: `${week}:${year}` });
      }
    };
    let content;
    if (weekColumnId === weekBoardDefaultColumnIds.NO_DUE_DATE) {
      content = getNoDueDateHeader();
    } else if (weekColumnId === weekBoardDefaultColumnIds.OVERDUE) {
      content = getOverdueHeader();
    } else {
      content = config.yColumns[index].dayColumn
        ? getDayColumnHeader(index, handleClick)
        : getWeekColumnHeader(index, handleClick);
    }
    return content;
  };

  const getWeekColumnHeader = (index, handleClick) => {
    const { config, classes, viewConfig } = props;
    const weekColumnId = config.yColumns[index].id;
    const className = getColumnClassName({ weekColumnId });
    const content = config.yColumns[index].name;
    const { dateFrom, dateTo } = getFromToDates(config.yColumns[index].date, viewConfig);
    // this hack is making lazy calculating PPC and prevents errors on non-updated cards
    // cause there is a case when we have new activeBoard and old questionCards in store at the same time
    // the technical debt to fix roots of it was created DARWIN-16806
    const ppc = viewConfig.showPPC ? getWeekPPC(dateFrom, dateTo) : {};
    return (
      <div className={classes.dateColumn}>
        <div className={classes.dateColumnContainer}>
          <div />
          <Avatar
            className={classNames(
              viewConfig.highReadability ? classes.weekLabelHighContrast : classes.weekLabel,
              className,
            )}>
            {`${content}`}
          </Avatar>
          <div className={classes.expandCollapseIconWrapper}>
            <Tooltip title={intl.get('board.views.week.tooltip.expand_week_days')}>
              <IconButton className={classes.expandCollapseIcon} onClick={handleClick}>
                <KeyboardArrowRight />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <span className={classes.progressContainer}>
          {viewConfig.showPPC && !!ppc.total && (
            <PPCProgress
              title={intl.get('board.toolbar.ppc.weekly')}
              viewConfig={viewConfig}
              className={classes.PPCProgress}
              style={{ width: 130 }}
              ppc={ppc}
              dateFrom={format(dateFrom, 'eee, dd MMM yyyy', {
                locale: getLocale(locale),
              })}
              dateTo={format(dateTo, 'eee, dd MMM yyyy', {
                locale: getLocale(locale),
              })}
            />
          )}
        </span>
      </div>
    );
  };

  const getDayColumnHeader = (index, handleClick) => {
    const { config, classes, viewConfig } = props;
    const weekColumnId = config.yColumns[index].id;
    const className = getColumnClassName({ weekColumnId });

    return (
      <div className={classes.dateColumn}>
        <div className={classes.dateColumnContainer}>
          <div />
          <Avatar
            className={classNames(
              viewConfig.highReadability ? classes.weekLabelHighContrast : classes.weekLabel,
              className,
            )}>
            {`${config.yColumns[index].weekNumber}`}
          </Avatar>
          <div className={classes.expandCollapseIconWrapper}>
            <Tooltip title={intl.get('board.views.week.tooltip.collapse_week_days')}>
              <IconButton className={classes.expandCollapseIcon} onClick={handleClick}>
                <KeyboardArrowLeft />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <span className={classes.dateLabel}>{`${format(
          config.yColumns[index].date,
          'eee, dd MMM yyyy',
          { locale: getLocale(locale) },
        )}`}</span>
      </div>
    );
  };

  const getOverdueHeader = () => {
    const { viewConfig, classes } = props;
    return (
      <Typography
        variant="body2"
        className={classNames(
          viewConfig.highReadability ? classes.weekLabelHighContrast : classes.weekLabel,
          !viewConfig.highReadability && classes.overdue,
        )}>
        {intl.get('board.views.week.columns.overdue')}
      </Typography>
    );
  };

  const getNoDueDateHeader = () => {
    const { viewConfig, classes } = props;
    return (
      <Typography
        className={viewConfig.highReadability ? classes.weekLabelHighContrast : classes.weekLabel}
        variant="body2">
        {intl.get('board.views.week.columns.no_due_date')}
      </Typography>
    );
  };

  const getWeekPPC = (dateFrom, dateTo) => {
    const { deliveries, cards, board } = props;
    const dels = extractDeliveries(deliveries, board.delivery_config);
    const questions = extractQuestions(cards, board.field_config);
    return calculatePPC(dateFrom, dateTo, questions.concat(dels));
  };

  const getFromToDates = endOfWeek => {
    const { viewConfig } = props;
    const week = getBeginAndEndOfWeek(endOfWeek);
    const dateFrom =
      startOfDay(viewConfig.dateFrom) < week.begin ? week.begin : startOfDay(viewConfig.dateFrom);
    const dateTo =
      startOfDay(viewConfig.dateTo) > week.end ? week.end : startOfDay(viewConfig.dateTo);
    return { dateFrom, dateTo };
  };

  const renderAnswered = answered => {
    const { viewConfig, classes, board, onCardClick } = props;
    return (
      <div>
        <ListItem
          classes={{
            root: viewConfig.highReadability ? classes.itemHeaderHighContrast : classes.itemHeader,
            selected: classes.selected,
          }}
          style={{
            width: 500,
            marginTop: 50,
          }}
          onClick={() => handleClick(ANSWERED_LIST_ID)}>
          {viewConfig.expandedWeekRows.indexOf(ANSWERED_LIST_ID) > -1 ? (
            <ExpandLess
              className={viewConfig.highReadability ? classes.iconHighContrast : classes.icon}
            />
          ) : (
            <ExpandMore
              className={viewConfig.highReadability ? classes.iconHighContrast : classes.icon}
            />
          )}
          <ListItemText
            classes={{
              root: classes.rootHeader,
              primary: viewConfig.highReadability ? classes.headerHighContrast : classes.header,
            }}
            className={classes.yColumnHeader}
            primary={intl.get('board.views.week.columns.answered', {
              amount: answered.length,
            })}
          />
        </ListItem>
        <Collapse
          in={viewConfig.expandedWeekRows.indexOf(ANSWERED_LIST_ID) > -1}
          timeout="auto"
          unmountOnExit>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <AnsweredCardsList
              data={answered}
              onCardClick={onCardClick}
              board={board}
              viewConfig={viewConfig}
            />
          </div>
        </Collapse>
      </div>
    );
  };

  const renderTable = (showMilestones, weekBoardStructure) => {
    const { config, viewConfig, classes } = props;

    return config.xColumns.map((row, rowIndex) => {
      if (rowIndex === 0 && !showMilestones) {
        return '';
      }
      return (
        <div key={rowIndex} style={{ marginTop: 5 }}>
          <ListItem
            classes={{
              root: viewConfig.highReadability
                ? classes.itemHeaderHighContrast
                : classes.itemHeader,
              selected: classes.selected,
            }}
            className={classNames(
              viewConfig.expandedWeekRows.indexOf(row.id) === -1 && classes.itemHeaderCollapsed,
            )}
            style={{
              width:
                config.yColumns.length *
                (viewConfig.highReadability ? HIGH_READABILITY_CELL_WIDTH : DEFAULT_CELL_WIDTH),
            }}
            onClick={() => handleClick(row.id)}>
            {viewConfig.expandedWeekRows.indexOf(row.id) > -1 ? (
              <ExpandLess
                className={viewConfig.highReadability ? classes.iconHighContrast : classes.icon}
              />
            ) : (
              <ExpandMore
                className={viewConfig.highReadability ? classes.iconHighContrast : classes.icon}
              />
            )}
            <ListItemText
              id={`col-${rowIndex}`}
              classes={{
                root: classes.rootHeader,
                primary: viewConfig.highReadability ? classes.headerHighContrast : classes.header,
              }}
              inset
              className={classes.yColumnHeader}
              primary={row.name || intl.get('board.views.week.columns.milestones')}
            />
          </ListItem>
          <Collapse
            in={viewConfig.expandedWeekRows.indexOf(row.id) > -1}
            timeout="auto"
            unmountOnExit>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {config.yColumns.map((column, columnIndex) =>
                getCell(rowIndex, columnIndex, weekBoardStructure[rowIndex][columnIndex].data),
              )}
            </div>
          </Collapse>
        </div>
      );
    });
  };

  const getCell = (rowIndex, columnIndex, cellData) => {
    const { viewConfig, onCardClick, board } = props;
    return (
      <Cell
        canDrop={canDropOnCell}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        key={`${rowIndex}-${columnIndex}`}
        expanded={viewConfig.expandedWeekCells.indexOf(`${rowIndex}-${columnIndex}`) > -1}
        onExpand={handleExpandCell}
        onCollapse={handleCollapseCell}
        data={cellData}
        type={rowIndex === 0 ? MILESTONE_CELL : DISCIPLINABLE_CELL}
        onCardClick={onCardClick}
        onUpdateCard={handleUpdateCard}
        board={board}
        viewConfig={viewConfig}
      />
    );
  };

  locale = intl.getInitOptions().currentLocale;

  const showMilestones = milestones && milestones.length > 0;
  const weekBoardStructure = getWeekBoardStructure(
    board,
    viewConfig,
    deliveries,
    cards,
    milestones,
    config,
  );

  return (
    <div
      style={{
        width,
        overflowX: 'scroll',
        WebkitOverflowScrolling: 'touch',
      }}>
      <div
        style={{
          width:
            config.yColumns.length *
            (viewConfig.highReadability ? HIGH_READABILITY_CELL_WIDTH : DEFAULT_CELL_WIDTH),
        }}>
        <Table>
          <TableHead>
            <TableRow
              style={{
                boxShadow:
                  '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
              }}>
              {config.yColumns.map((column, index) => (
                <TableCell
                  key={`${index}-${column.id}`}
                  classes={{
                    head: classes.cellRoot,
                  }}
                  style={{
                    width: viewConfig.highReadability
                      ? HIGH_READABILITY_CELL_WIDTH
                      : DEFAULT_CELL_WIDTH,
                  }}>
                  <div
                    className={classNames(
                      viewConfig.highReadability
                        ? classes.weekHeaderHighContrast
                        : classes.weekHeader,
                      getCellClassName({
                        columnIndex: index,
                        rowIndex: 0,
                        config,
                      }),
                    )}>
                    {getYHeader({ index })}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </Table>
        <div
          ref={tableWrapperRef}
          style={{
            height: window.innerHeight - 258,
            overflowY: 'auto',
            overflowX: 'hidden',
          }}>
          {renderTable(showMilestones, weekBoardStructure)}
          {renderAnswered(weekBoardStructure[ANSWERED_ROW])}
        </div>
      </div>
    </div>
  );
};

WeekBoard.propTypes = {
  cards: PropTypes.array.isRequired,
  onExpandCollapseColumn: PropTypes.func.isRequired,
  onUpdateCard: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCardClick: PropTypes.func.isRequired,
  milestones: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deliveries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  board: PropTypes.shape({
    id: PropTypes.string,
    field_config: PropTypes.shape({}),
    milestones_config: PropTypes.arrayOf(PropTypes.shape({})),
    delivery_config: PropTypes.shape({
      delivery_met_date_field: PropTypes.string,
      delivery_from_field: PropTypes.string,
      delivery_date_field: PropTypes.string,
    }),
  }).isRequired,
  classes: PropTypes.shape({
    labelCellRootWeekEnd: PropTypes.string,
    rootWeekEnd: PropTypes.string,
    labelCellRootWeekDay: PropTypes.string,
    rootWeekDay: PropTypes.string,
    labelCellRoot: PropTypes.string,
    root: PropTypes.string,
    cellRoot: PropTypes.string,
    yColumnHeader: PropTypes.string,
    header: PropTypes.string,
    headerHighContrast: PropTypes.string,
    rootHeader: PropTypes.string,
    icon: PropTypes.string,
    iconHighContrast: PropTypes.string,
    selected: PropTypes.string,
    itemHeaderCollapsed: PropTypes.string,
    itemHeader: PropTypes.string,
    itemHeaderHighContrast: PropTypes.string,
    weekHeader: PropTypes.string,
    weekHeaderHighContrast: PropTypes.string,
    weekLabel: PropTypes.string,
    expandCollapseIcon: PropTypes.string,
    weekLabelHighContrast: PropTypes.string,
    overdue: PropTypes.string,
    PPCProgress: PropTypes.string,
    progressContainer: PropTypes.string,
    dateColumn: PropTypes.string,
    dateLabel: PropTypes.string,
    expandCollapseIconWrapper: PropTypes.string,
    dateColumnContainer: PropTypes.string,
    week: PropTypes.string,
    currentWeekDayColumn: PropTypes.string,
    currentWeekHighReadability: PropTypes.string,
    currentWeek: PropTypes.string,
    dayColumn: PropTypes.string,
  }).isRequired,
  viewConfig: PropTypes.shape({
    dateFrom: PropTypes.instanceOf(Date),
    dateTo: PropTypes.instanceOf(Date),
    highReadability: PropTypes.bool,
    expandedWeekRows: PropTypes.arrayOf(PropTypes.shape({})),
    expandedWeekCells: PropTypes.arrayOf(PropTypes.shape({})),
    set: PropTypes.func,
    showDeliveries: PropTypes.bool,
    showQuestions: PropTypes.bool,
    showPPC: PropTypes.bool,
    field_filters: PropTypes.shape({}),
    stepIdsToShowOnBoard: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  config: PropTypes.shape({
    yColumns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        date: PropTypes.dateTime,
        weekNumber: PropTypes.number,
        year: PropTypes.number,
        name: PropTypes.string,
        dayColumn: PropTypes.bool,
      }),
    ),
    xColumns: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])),
    milestones: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onSetScrollPos: PropTypes.func.isRequired,
  scrollPos: PropTypes.shape({
    scrollX: PropTypes.number,
    scrollY: PropTypes.number,
  }).isRequired,
};

export default withStyles(styles)(WeekBoard);
