import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { DropTarget } from 'react-dnd';
import green from '@material-ui/core/colors/green';

import {
  DEFAULT_CELL_WIDTH,
  DELIVERY,
  HIGH_READABILITY_CELL_WIDTH,
  HIGH_READABILITY_MINIMUM_CELL_WIDTH,
  MILESTONE_CELL,
  MINIMUM_CELL_WIDTH,
} from '../../utils/Constants';
import CardList from '../../common/CardList';
import CardFolderOpen from '../../common/CardFolderOpen';
import CardFolderClosed from '../../common/CardFolderClosed';
import MilestoneCardItem from './MilestoneCardItem';
import { calculateCellWidth, getEntryTitle, QUESTION_TITLE } from '../../utils/FieldUtil';

const styles = {
  cell: {
    backgroundColor: '#e4e4e4',
    // We would like to have this line in order to make use of all the available space. However, it causes bugs in Edge
    // and Firefox. See DARWIN-17264.
    // flexGrow: 1,
    marginTop: 2,
    marginLeft: 2,
    marginBottom: 2,
    minHeight: 54,
    borderRadius: 4,
  },
  cellHighReadability: {
    backgroundColor: '#bdbdbd',
    marginTop: 4,
    marginLeft: 4,
    marginBottom: 4,
    minHeight: 70,
    borderRadius: 4,
  },
  cellIsOver: {
    backgroundColor: green[100],
  },
};

const cardTarget = {
  canDrop(props, monitor) {
    const { columnIndex, rowIndex } = props;
    return props.canDrop({
      card: monitor.getItem().card,
      sourceRowIndex: monitor.getItem().rowIndex,
      sourceColumnIndex: monitor.getItem().columnIndex,
      columnIndex,
      rowIndex,
    });
  },

  drop(props, monitor) {
    const item = monitor.getItem();
    const { rowIndex, columnIndex } = props;
    props.onUpdateCard({
      card: item.card,
      rowIndex,
      columnIndex,
      sourceRowIndex: monitor.getItem().rowIndex,
      sourceColumnIndex: monitor.getItem().columnIndex,
    });
  },
};

const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
});

const getCardCount = (questions, deliveries) => questions.length + deliveries.length;

const Cell = props => {
  const {
    classes,
    expanded,
    data,
    board,
    canDrop,
    isOver,
    connectDropTarget,
    onCardClick,
    viewConfig,
    type,
    rowIndex,
    columnIndex,
    onExpand,
    onCollapse,
  } = props;

  const handleExpand = () => {
    onExpand({ key: `${rowIndex}-${columnIndex}` });
  };

  const handleCollapse = () => {
    onCollapse({ key: `${rowIndex}-${columnIndex}` });
  };

  const calculateQuestionCellWidth = ({ fields, viewConfig }) => {
    if (viewConfig.highReadability) {
      return calculateCellWidth({
        areCellsExpanded: viewConfig.expandedTileCells.length === 0,
        fields,
        minimumCellWidth: HIGH_READABILITY_MINIMUM_CELL_WIDTH,
        defaultWidth: HIGH_READABILITY_CELL_WIDTH,
        titleId: viewConfig[QUESTION_TITLE] || getEntryTitle(fields),
      });
    }

    return calculateCellWidth({
      areCellsExpanded: viewConfig.expandedTileCells.length === 0,
      fields,
      minimumCellWidth: MINIMUM_CELL_WIDTH,
      defaultWidth: DEFAULT_CELL_WIDTH,
      titleId: viewConfig[QUESTION_TITLE] || getEntryTitle(fields),
    });
  };

  const width =
    type === 'QUESTION'
      ? calculateQuestionCellWidth({
          fields: board.field_config.fields,
          viewConfig,
        })
      : viewConfig.highReadability
      ? HIGH_READABILITY_CELL_WIDTH
      : DEFAULT_CELL_WIDTH;

  if (MILESTONE_CELL === type) {
    return connectDropTarget(
      <div
        className={classNames(
          viewConfig.highReadability ? classes.cellHighReadability : classes.cell,
          isOver && canDrop && classes.cellIsOver,
        )}
        style={{ width }}>
        <CardList
          board={board}
          viewConfig={viewConfig}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          items={data}
          CardComponent={MilestoneCardItem}
          onCardClick={onCardClick}
          type={type}
        />
      </div>,
    );
  }

  const questions = data.filter(item => item.type !== DELIVERY);
  const deliveries = data.filter(item => item.type === DELIVERY);
  const cardCount = getCardCount(questions, deliveries);

  return connectDropTarget(
    <div
      className={classNames(
        viewConfig.highReadability ? classes.cellHighReadability : classes.cell,
        isOver && canDrop && classes.cellIsOver,
      )}
      style={{ width }}>
      {(!expanded || cardCount === 0) && (
        <CardFolderClosed viewConfig={viewConfig} cardCount={cardCount} onExpand={handleExpand} />
      )}
      {expanded && cardCount > 0 && (
        <CardFolderOpen
          board={board}
          viewConfig={viewConfig}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          questions={questions}
          deliveries={deliveries}
          onCollapse={handleCollapse}
          onCardClick={onCardClick}
          type={type}
        />
      )}
    </div>,
  );
};

Cell.propTypes = {
  classes: PropTypes.shape({
    cellHighReadability: PropTypes.string,
    cell: PropTypes.string,
    cellIsOver: PropTypes.string,
  }),
  expanded: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  board: PropTypes.shape({
    field_config: PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  canDrop: PropTypes.bool.isRequired,
  rowIndex: PropTypes.number.isRequired,
  columnIndex: PropTypes.number.isRequired,
  isOver: PropTypes.bool.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  onCardClick: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
  viewConfig: PropTypes.shape({
    highReadability: PropTypes.bool,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), DropTarget('CARD', cardTarget, collect))(Cell);
