import React from 'react';
import { compose } from 'recompose';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import MailIcon from '@material-ui/icons/Note';
import Badge from '@material-ui/core/Badge';
import amber from '@material-ui/core/colors/amber';
import { DropTarget } from 'react-dnd';

import { canMoveToStep } from '../../utils/PermissionUtils';
import { LIST_BOARD_VIEW_MODE } from '../../utils/ViewModeUtil';
import CardFolderOpen from '../../common/CardFolderOpen';
import CardFolderClosed from '../../common/CardFolderClosed';
import PropTypes from 'prop-types';

const styles = {
  step: {
    marginTop: 25,
    width: '100%',
  },
  stepHeader: {
    backgroundColor: '#f3f3f3',
    height: 10,
    borderBottom: '#dedede 1px solid',
  },
  stepBackground: {
    backgroundColor: '#e4e4e4',
  },
  stepIsOver: {
    backgroundColor: green[200],
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    color: amber[500],
    width: 48,
    height: 48,
  },
  iconHighReadability: {
    color: amber[500],
    width: 64,
    height: 64,
  },
  iconBadge: {
    top: 0,
    right: -2,
    transform: 'scale(1)',
  },
  iconBadgeHighReadability: {
    top: 0,
    right: -2,
    transform: 'scale(1.5)',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  cardContentHighReadability: {
    backgroundColor: '#bdbdbd',
  },
};

const cardTarget = {
  canDrop(props, monitor) {
    return canMoveToStep({
      card: monitor.getItem().card,
      step: props.step,
      stepConfig: props.board.step_config,
    });
  },

  drop(props, monitor) {
    const item = monitor.getItem();
    props.onMoveCardToStep({
      card: item.card,
      step: props.step,
      board: props.board,
    });
  },
};

const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
});

const Step = props => {
  const {
    classes,
    cards,
    step,
    board,
    viewConfig,
    expanded,
    connectDropTarget,
    canDrop,
    isOver,
    onCardClick,
    onExpand,
    onCollapse,
    history,
  } = props;

  const handleExpand = () => {
    onExpand(step.id);
  };

  const handleCollapse = () => {
    onCollapse(step.id);
  };

  const handleDecisionLogStepClick = () => {
    history.push(
      `/${board.id}?view=${LIST_BOARD_VIEW_MODE}&step=${board.step_config.decision_log_step_id}`,
    );
  };

  let children;

  if (step.id === board.step_config.decision_log_step_id) {
    children = (
      <CardContent
        className={classNames(
          viewConfig.highReadability && classes.cardContentHighReadability,
          classes.cardContent,
        )}>
        <IconButton
          id={'decision-log-step'}
          onClick={handleDecisionLogStepClick}
          className={classes.iconButton}>
          <Badge
            classes={{
              badge: viewConfig.highReadability
                ? classes.iconBadgeHighReadability
                : classes.iconBadge,
            }}
            badgeContent={`${cards.length}`}
            color="primary">
            <MailIcon
              className={viewConfig.highReadability ? classes.iconHighReadability : classes.icon}
            />
          </Badge>
        </IconButton>
      </CardContent>
    );
  } else {
    children = (
      <CardContent
        className={viewConfig.highReadability ? classes.cardContentHighReadability : null}>
        {!expanded && (
          <CardFolderClosed
            viewConfig={viewConfig}
            cardCount={cards.length}
            onExpand={handleExpand}
          />
        )}
        {expanded && (
          <CardFolderOpen
            board={board}
            viewConfig={viewConfig}
            questions={cards}
            onCollapse={handleCollapse}
            onCardClick={onCardClick}
          />
        )}
      </CardContent>
    );
  }

  return connectDropTarget(
    <div className={classNames(classes.step, isOver && canDrop && classes.stepIsOver)}>
      <Card
        key={`step-${step.id}`}
        className={classNames(classes.stepBackground, isOver && canDrop && classes.stepIsOver)}>
        <CardHeader
          className={classes.stepHeader}
          title={<Typography variant="body2">{step.name}</Typography>}
          action={step.final && <LockIcon />}
        />
        {children}
      </Card>
    </div>,
  );
};

Step.propTypes = {
  classes: PropTypes.object.isRequired,
  cards: PropTypes.array.isRequired,
  step: PropTypes.object.isRequired,
  board: PropTypes.object.isRequired,
  viewConfig: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  connectDropTarget: PropTypes.func.isRequired,
  canDrop: PropTypes.bool.isRequired,
  isOver: PropTypes.bool.isRequired,
  onCardClick: PropTypes.func.isRequired,
  onExpand: PropTypes.func,
  onCollapse: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

Step.defaultProps = {
  expanded: false,
  onExpand: () => {},
  onCollapse: () => {},
  history: {},
};

export default compose(withStyles(styles), DropTarget('CARD', cardTarget, collect))(Step);
