import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';

const styles = () => ({
  dialog: {
    width: 'calc(100% - 16px)',
  },
  root: {
    height: 110,
  },
  listItem: {
    lineHeight: 1.43,
    fontSize: '0.875rem',
  },
  subheader: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    height: 56,
    lineHeight: '56px',
    textAlign: 'left',
    paddingLeft: 24,
  },
});

const DeleteAttachmentsDialog = ({ open, record, onClose, onDelete, classes }) => {
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle>{intl.get('common.attachments.delete')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{intl.get('common.attachments.delete.confirmation')}</DialogContentText>
        <Paper className={classes.root}>
          <List
            subheader={
              <ListSubheader className={classes.subheader}>
                {intl.get('common.attachments.columns.name')}
              </ListSubheader>
            }>
            <React.Fragment>
              <Divider />
              <ListItem style={{ height: 53 }}>
                <ListItemText classes={{ primary: classes.listItem }} primary={record.name} />
              </ListItem>
            </React.Fragment>
          </List>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {intl.get('common.dialog.cancel')}
        </Button>
        <Button onClick={onDelete} color="secondary">
          {intl.get('common.dialog.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteAttachmentsDialog.propTypes = {
  open: PropTypes.bool,
  classes: PropTypes.shape({
    dialog: PropTypes.string,
    root: PropTypes.string,
    subheader: PropTypes.string,
    listItem: PropTypes.string,
  }),
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  record: PropTypes.shape({
    name: PropTypes.string,
  }),
};

DeleteAttachmentsDialog.defaultProps = {
  open: false,
  classes: {},
  onClose: () => {},
  disabled: false,
  record: {},
};

export default withStyles(styles)(DeleteAttachmentsDialog);
