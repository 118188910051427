import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import intl from 'react-intl-universal';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { URLSearchParams } from '../../utils/UrlUtil';

import { alertAction } from '../../thunks/Alerts';
import { selectActiveBoard, selectIsLoadingActiveBoard } from '../../ducks/Boards';
import { actions as roomActions, selectActiveRoom } from '../../ducks/Rooms';
import { actions as communityActions, selectActiveCommunity } from '../../ducks/Communities';
import { actions as viewActions, selectViewConfig } from '../../ducks/Cards';
import * as boardsActions from '../../thunks/Boards';
import * as subscriberActions from '../../thunks/Subscribers';
import * as cardsActions from '../../thunks/Cards';
import {
  LIST_BOARD_VIEW_MODE,
  LIST_DELIVERY_VIEW_MODE,
  LIST_MILESTONE_VIEW_MODE,
  TILE_BOARD_VIEW_MODE,
  WEEK_BOARD_VIEW_MODE,
} from '../../utils/ViewModeUtil';
import Error from '../../common/Error';
import ListBoardContainer from './ListBoardContainer';
import TileBoardContainer from './TileBoardContainer';
import WeekBoardContainer from './WeekBoardContainer';
import { hasResponsibleField } from '../../utils/FieldUtil';
import ListMilestoneContainer from './ListMilestoneContainer';
import ListDeliveryContainer from './ListDeliveryContainer';

const mapStateToProps = () =>
  createStructuredSelector({
    activeBoard: selectActiveBoard(),
    activeRoom: selectActiveRoom(),
    activeCommunity: selectActiveCommunity(),
    isLoadingActiveBoard: selectIsLoadingActiveBoard(),
    viewConfig: selectViewConfig(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...boardsActions,
      ...subscriberActions,
      ...cardsActions,
      ...viewActions,
      setActiveRoom: roomActions.setActiveRoom,
      setActiveCommunity: communityActions.setActiveCommunity,
    },
    dispatch,
  ),
});

const BoardContainer = props => {
  const {
    activeBoard,
    activeCommunity,
    activeRoom,
    isLoadingActiveBoard,
    viewConfig,
    location,
    match,
    actions,
  } = props;

  const handleFetchPossibleResponsible = useCallback(
    ({ disciplines }) => {
      if (hasResponsibleField({ fieldConfig: activeBoard?.field_config })) {
        actions
          .fetchPossibleResponsible({ boardId: activeBoard.id, disciplines })
          .then(action => alertAction({ action, error: intl.get('card.responsible.error') }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeBoard],
  );

  const fetchPossibleResponsible = useCallback(
    activeBoard => {
      // eslint-disable-next-line react/prop-types
      const toValue = activeBoard.field_config.allowed_to_authorities.map(
        discipline => discipline.id,
      );
      handleFetchPossibleResponsible({ disciplines: toValue });
    },
    [handleFetchPossibleResponsible],
  );

  useEffect(() => {
    if (activeBoard === null || activeBoard.id.toString() !== match.params.boardId) {
      actions.fetchActiveBoard({ boardId: match.params.boardId }).then(action => {
        const workflowConfig = action.payload.activeBoard.workflow_config;

        if (activeCommunity !== workflowConfig.community_id) {
          actions.setActiveCommunity({
            communityId: workflowConfig.community_id,
          });
        }
        if (activeRoom !== workflowConfig.room_id) {
          actions.setActiveRoom({
            roomId: workflowConfig.room_id,
          });
        }

        fetchPossibleResponsible(action.payload.activeBoard);
        actions.setViewConfig({
          ...viewConfig,
          stepIdsToShowOnBoard: [action.payload.activeBoard.step_config.initial_step_id],
        });
        return alertAction({
          action,
          error: intl.get('board.fetch.error'),
        });
      });
    }

    if (activeBoard) {
      fetchPossibleResponsible(activeBoard);
      if (viewConfig.stepIdsToShowOnBoard.length < 1) {
        actions.setViewConfig({
          ...viewConfig,
          stepIdsToShowOnBoard: [activeBoard.step_config.initial_step_id],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoadingActiveBoard) {
    return <LinearProgress />;
  }
  if (!activeBoard) {
    return <Error text={intl.get('board.not_found')} />;
  }

  const view = new URLSearchParams(location.search).get('view');

  // Default view is tile board
  if (!view || view === TILE_BOARD_VIEW_MODE) {
    return <TileBoardContainer />;
  }

  if (view === WEEK_BOARD_VIEW_MODE) {
    return <WeekBoardContainer />;
  }

  if (view === LIST_BOARD_VIEW_MODE) {
    return <ListBoardContainer />;
  }

  if (view === LIST_MILESTONE_VIEW_MODE) {
    return <ListMilestoneContainer />;
  }

  if (view === LIST_DELIVERY_VIEW_MODE) {
    return <ListDeliveryContainer />;
  }

  return (
    <Error
      text={intl.get('board.views.not_found', {
        mode: view,
        available_modes: [TILE_BOARD_VIEW_MODE, LIST_BOARD_VIEW_MODE],
      })}
    />
  );
};

BoardContainer.propTypes = {
  activeBoard: PropTypes.shape({
    id: PropTypes.string,
    field_config: PropTypes.shape({}),
    step_config: PropTypes.shape({
      initial_step_id: PropTypes.string,
    }),
  }),
  isLoadingActiveBoard: PropTypes.bool.isRequired,
  activeCommunity: PropTypes.string,
  activeRoom: PropTypes.string,
  viewConfig: PropTypes.shape({
    stepIdsToShowOnBoard: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      boardId: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    fetchActiveBoard: PropTypes.func,
    setActiveCommunity: PropTypes.func,
    setActiveRoom: PropTypes.func,
    setViewConfig: PropTypes.func,
    fetchPossibleResponsible: PropTypes.func,
  }),
};

BoardContainer.defaultProps = {
  activeBoard: null,
  location: {},
  match: {},
  actions: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(BoardContainer);
