import React from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth/withWidth';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { renderSelectField } from '../../utils/RenderUtil';
import { isShowingOnlyDefaultStep } from '../../utils/FilterUtil';

const styles = {
  divider: {
    marginTop: 10,
  },
  menuList: {
    outline: 'none',
  },
  resetFilter: {
    marginLeft: 24,
    height: 32,
  },
  field: {
    marginLeft: 16,
    marginRight: 16,
    paddingBottom: 3,
    height: 48,
  },
  filterContainer: { outline: 'none' },
};

const StepsFilter = ({
  title,
  resetTitle,
  viewConfig,
  classes,
  board,
  stepIdsToShowOnBoard,
  onChange,
}) => {
  const handleResetFilter = () => {
    onChange(viewConfig.setIn(['stepIdsToShowOnBoard'], [board.step_config.initial_step_id]));
  };

  const handleFilterChange = event => {
    onChange(viewConfig.setIn(['stepIdsToShowOnBoard'], event.target.value));
  };

  const listField = ({ board, viewConfig, style }) => {
    return [
      renderSelectField({
        style,
        name: 'stepsToShowOnBoard',
        label: intl.get('docs.steps'),
        // eslint-disable-next-line react/prop-types
        value: viewConfig.stepIdsToShowOnBoard,
        handleChange: handleFilterChange,
        options: board.step_config.steps,
        multiple: true,
      }),
    ];
  };

  return (
    <div className={classes.filterContainer}>
      <Divider className={classes.divider} />
      <ListSubheader className={classes.menuList}>
        {title}
        <Button
          className={classes.resetFilter}
          size="small"
          variant="outlined"
          disabled={isShowingOnlyDefaultStep(board, stepIdsToShowOnBoard)}
          onClick={handleResetFilter}>
          {resetTitle}
        </Button>
      </ListSubheader>
      <MenuItem key={'steps'} className={classes.field}>
        {listField({
          board,
          field: {},
          viewConfig,
          style: { width: 250 },
        })}
      </MenuItem>
    </div>
  );
};

StepsFilter.propTypes = {
  title: PropTypes.string.isRequired,
  resetTitle: PropTypes.string.isRequired,
  viewConfig: PropTypes.shape({
    setIn: PropTypes.func,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  board: PropTypes.object.isRequired,
  stepIdsToShowOnBoard: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(withWidth()(StepsFilter));
