import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

import CardItemTooltip from './CardItemTooltip';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CommentIcon from '@material-ui/icons/ModeCommentOutlined';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import intl from 'react-intl-universal';
import QuestionIcon from '@material-ui/icons/HelpRounded';
import DraggableCard from './DraggableCard';
import {
  getCardDate,
  getQuestionFieldValuesToDisplayInTooltip,
  getTitle,
} from '../../utils/FieldUtil';

const styles = {
  cell: {
    backgroundColor: '#e4e4e4',
    marginTop: 2,
    marginLeft: 2,
    marginBottom: 2,
    minHeight: 200,
    borderRadius: 4,
    width: '100%',
  },
  cellHighReadability: {
    backgroundColor: '#bdbdbd',
    marginTop: 4,
    marginLeft: 4,
    marginBottom: 4,
    minHeight: 470,
    borderRadius: 4,
    width: '100%',
  },
  cellIsOver: {
    backgroundColor: green[100],
  },
  card: {
    float: 'left',
    width: 150,
    margin: 8,
    '&:hover': {
      cursor: 'pointer',
    },
    position: 'relative',
    background: '#f5f2f1',
    minHeight: 38,
    border: '2px dashed transparent;',
  },
  cardHighReadability: {
    float: 'left',
    width: 250,
    margin: 8,
    '&:hover': {
      cursor: 'pointer',
    },
    position: 'relative',
    background: '#f5f2f1',
    minHeight: 56,
  },
  title: {
    maxHeight: 30,
    overflow: 'hidden',
    fontSize: 14,
    lineHeight: '15px',
    padding: '6px 14px 0 38px',
    textOverflow: 'ellipsis',
  },
  titleHighReadability: {
    maxHeight: 49,
    overflow: 'hidden',
    fontSize: 24,
    lineHeight: '24px',
    padding: '8px 22px 0 52px',
    textOverflow: 'ellipsis',
  },
  info: {
    display: 'flex',
    paddingRight: '5px',
    fontSize: 10,
    fontWeight: 100,
  },
  infoHighReadability: {
    display: 'flex',
    paddingTop: '2px',
    paddingBottom: '2px',
    fontSize: 18,
    fontWeight: 100,
  },
  typeIconContainer: {
    borderRadius: '50%',
    position: 'absolute',
    top: 4,
    display: 'inline-block',
  },
  tag: {
    height: 26,
    width: 6,
    borderRadius: 3,
    position: 'absolute',
    right: 3,
    top: 6,
    display: 'inline-block',
  },
  tagHighReadability: {
    height: 42,
    width: 12,
    borderRadius: 6,
    position: 'absolute',
    right: 3,
    top: 6,
    display: 'inline-block',
  },
  icon: {
    height: 14,
    width: 14,
  },
  iconHighReadability: {
    height: 24,
    width: 24,
  },
  typeIcon: {
    height: 32,
    width: 32,
    color: '#3eb1c8',
  },
  typeIconHighReadability: {
    height: 48,
    width: 48,
    color: '#3eb1c8',
  },
  statusGreen: {
    backgroundColor: '#309269',
  },
};

const AnsweredCardsList = ({ classes, data, board, onCardClick, viewConfig }) => {
  const locale = intl.getInitOptions().currentLocale;

  return (
    <div
      className={classNames(
        viewConfig.highReadability ? classes.cellHighReadability : classes.cell,
      )}>
      {data.map((item, index) => {
        const showAttachments = viewConfig.showAttachments && item.child_count > 0;
        const showComments = viewConfig.showComments && item.comment_count > 0;
        const commentCaption = intl.get(
          item.comment_count === 1 ? 'boards.tooltips.comment' : 'boards.tooltips.comments',
        );
        const attachmentCaption = intl.get(
          item.child_count === 1 ? 'boards.tooltips.attachment' : 'boards.tooltips.attachments',
        );
        return (
          <DraggableCard key={`item-${index}`} card={item} board={board}>
            <Paper
              className={classNames(
                viewConfig.highReadability ? classes.cardHighReadability : classes.card,
              )}
              elevation={1}
              onClick={() => {
                onCardClick({ card: item });
              }}>
              <Tooltip title={intl.get('board.tooltips.question')}>
                <div className={classes.typeIconContainer}>
                  {
                    <QuestionIcon
                      className={
                        viewConfig.highReadability
                          ? classes.typeIconHighReadability
                          : classes.typeIcon
                      }
                    />
                  }
                </div>
              </Tooltip>
              <Tooltip title={intl.get('board.tooltips.question.answered')}>
                <div
                  className={classNames(
                    viewConfig.highReadability ? classes.tagHighReadability : classes.tag,
                    classes.statusGreen,
                  )}
                />
              </Tooltip>
              <CardItemTooltip
                viewConfig={viewConfig}
                map={getQuestionFieldValuesToDisplayInTooltip(item)}>
                <div>
                  <Typography
                    className={
                      viewConfig.highReadability ? classes.titleHighReadability : classes.title
                    }
                    gutterBottom>
                    {getTitle(item, viewConfig, 'questionTitle')}
                  </Typography>
                  <div
                    className={
                      viewConfig.highReadability ? classes.infoHighReadability : classes.info
                    }
                    style={{ paddingLeft: 40 }}>
                    <span style={{ height: 20, marginRight: 1 }}>
                      {getCardDate(item, board, locale)}
                    </span>
                    {showComments && (
                      <Tooltip title={String(item.comment_count) + ' ' + commentCaption}>
                        <CommentIcon
                          className={
                            viewConfig.highReadability ? classes.iconHighReadability : classes.icon
                          }
                        />
                      </Tooltip>
                    )}
                    {showAttachments && (
                      <Tooltip title={String(item.child_count) + ' ' + attachmentCaption}>
                        <AttachmentIcon
                          className={
                            viewConfig.highReadability ? classes.iconHighReadability : classes.icon
                          }
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </CardItemTooltip>
            </Paper>
          </DraggableCard>
        );
      })}
    </div>
  );
};

AnsweredCardsList.propTypes = {
  classes: PropTypes.shape({
    cellHighReadability: PropTypes.string,
    cell: PropTypes.string,
    cellIsOver: PropTypes.string,
    cardHighReadability: PropTypes.string,
    card: PropTypes.string,
    typeIconContainer: PropTypes.string,
    typeIconHighReadability: PropTypes.string,
    typeIcon: PropTypes.string,
    tagHighReadability: PropTypes.string,
    tag: PropTypes.string,
    statusGreen: PropTypes.string,
    title: PropTypes.string,
    titleHighReadability: PropTypes.string,
    info: PropTypes.string,
    infoHighReadability: PropTypes.string,
    icon: PropTypes.string,
    iconHighReadability: PropTypes.string,
  }),
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  board: PropTypes.shape({
    field_config: PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  onCardClick: PropTypes.func.isRequired,
  viewConfig: PropTypes.shape({
    highReadability: PropTypes.bool,
    showAttachments: PropTypes.bool,
    showComments: PropTypes.bool,
  }).isRequired,
  onExpand: PropTypes.func,
  rowIndex: PropTypes.number.isRequired,
  columnIndex: PropTypes.number.isRequired,
};

AnsweredCardsList.defaultProps = {
  onExpand: () => {},
};

export default compose(withStyles(styles))(AnsweredCardsList);
