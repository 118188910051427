import React from 'react';
import Menu from '@material-ui/core/Menu';

import intl from 'react-intl-universal';

import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import FromToDatePicker from './FromToDatePicker';
import { addYears } from 'date-fns';
import { getNoonOfDay } from '../utils/DateUtil';
import { actions as viewActions } from '../ducks/Cards';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CalendarIcon from '@material-ui/icons/DateRange';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';

const styles = () => ({
  ppcCalendar: {
    marginRight: 20,
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setViewConfig: viewActions.setViewConfig,
    },
    dispatch,
  ),
});

const PPCPeriodPicker = ({ viewConfig, actions, classes }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleConfigChange = viewConfig => {
    actions.setViewConfig(viewConfig);
  };

  const handleDateChange = (field, value) => {
    handleConfigChange(viewConfig.set('ppc_' + field, getNoonOfDay(value)));
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Tooltip title={intl.get('board.toolbar.ppc.change_period')}>
        <IconButton
          size="small"
          className={classes.ppcCalendar}
          color="primary"
          onClick={handleClick}>
          <CalendarIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <ListSubheader style={{ outline: 'none' }}>
          {intl.get('board.toolbar.ppc.change_period')}
        </ListSubheader>
        <MenuItem>
          <FromToDatePicker
            minDateFrom={addYears(Date.now(), -5)}
            maxDateFrom={viewConfig.ppc_dateTo}
            valueFrom={viewConfig.ppc_dateFrom}
            minDateTo={viewConfig.ppc_dateFrom}
            maxDateTo={addYears(Date.now(), 5)}
            valueTo={viewConfig.ppc_dateTo}
            handleChange={handleDateChange}
          />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

PPCPeriodPicker.propTypes = {
  viewConfig: PropTypes.shape({
    set: PropTypes.func,
    ppc_dateTo: PropTypes.instanceOf(Date),
    ppc_dateFrom: PropTypes.instanceOf(Date),
  }).isRequired,
  actions: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(PPCPeriodPicker)));
