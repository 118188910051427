import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withStyles } from '@material-ui/core/styles';
import CardItem from './CardItem';
import { STATUS_RED, STATUS_GREEN } from './CardItem';
import {
  deliveryStatusMessages,
  getDeliveryFieldValuesToDisplayInTooltip,
} from '../../utils/FieldUtil';
import { DELIVERY } from '../../utils/Constants';
import DeliveryIcon from '@material-ui/icons/LocalShippingOutlined';

const styles = () => ({
  typeIcon: {
    height: 32,
    width: 32,
    color: '#3eb1c8',
  },
  typeIconHighReadability: {
    height: 48,
    width: 48,
    color: '#3eb1c8',
  },
});

function isDelivered(card) {
  return !!card.metDate;
}

const DeliveryCardItem = ({
  classes,
  card,
  board,
  viewConfig,
  onCardClick,
  rowIndex,
  columnIndex,
  isMarked,
  type,
}) => {
  const isMet = isDelivered(card);

  return (
    <CardItem
      key={`card-${card.id}`}
      card={card}
      board={board}
      viewConfig={viewConfig}
      onCardClick={card => {
        onCardClick({ card, type: DELIVERY });
      }}
      getFieldMap={getDeliveryFieldValuesToDisplayInTooltip}
      typeIcon={
        <DeliveryIcon
          className={
            viewConfig.highReadability ? classes.typeIconHighReadability : classes.typeIcon
          }
        />
      }
      typeIconTooltip={intl.get('board.tooltips.delivery')}
      statusColour={isMet ? STATUS_GREEN : STATUS_RED}
      statusTooltip={intl.get(deliveryStatusMessages[card.status])}
      titleField="deliveryTitle"
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      isMarked={isMarked}
      type={type}
    />
  );
};

DeliveryCardItem.propTypes = {
  card: PropTypes.object.isRequired,
  board: PropTypes.object.isRequired,
  viewConfig: PropTypes.object.isRequired,
  onCardClick: PropTypes.func,
  rowIndex: PropTypes.number,
  columnIndex: PropTypes.number,
  isMarked: PropTypes.bool,
  type: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    typeIconHighReadability: PropTypes.string,
    typeIcon: PropTypes.string,
  }).isRequired,
};

DeliveryCardItem.defaultProps = {
  onCardClick: () => {},
  rowIndex: 0,
  columnIndex: 0,
  isMarked: false,
};

export default withStyles(styles)(DeliveryCardItem);
