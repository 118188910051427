import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import Cell from './Cell';
import { getFilters } from '../../utils/FilterUtil';
import {
  getBoardFields,
  isUnassignedColumn,
  calculateToValue,
  QUESTION_TITLE,
  calculateCellWidth,
  getEntryTitle,
} from '../../utils/FieldUtil';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import {
  DEFAULT_CELL_WIDTH,
  HIGH_READABILITY_CELL_WIDTH,
  HIGH_READABILITY_MINIMUM_CELL_WIDTH,
  MINIMUM_CELL_WIDTH,
  QUESTION,
} from '../../utils/Constants';
import { find } from 'lodash';
import { cardSorter } from '../../utils/SortUtil';

const styles = {
  cellRoot: {
    width: 200,
    padding: '4px 0px 4px 0px',
    textAlign: 'center',
  },
  headerColumn: {
    fontSize: 14,
  },
  headerColumnHighReadability: {
    fontSize: 24,
  },
  truncate: {
    padding: '0px 2px',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  header: {
    fontSize: 14,
  },
  headerHighReadability: {
    fontSize: 24,
    fontWeight: 'bold',
    marginLeft: 10,
    marginTop: 5,
  },
  icon: {
    height: 16,
    width: 16,
  },
  iconHighReadability: {
    height: 32,
    width: 32,
  },
  itemHeaderHighReadability: {
    height: 50,
    cursor: 'pointer',
  },
  itemHeader: {
    height: 10,
    cursor: 'pointer',
  },
  itemHeaderCollapsed: {
    borderBottom: '1px solid lightgrey',
  },
  selected: {
    backgroundColor: '#EAEAEA',
  },
  yColumnHeader: {
    padding: '6px 0 0 10px',
  },
  rootHeader: {
    marginTop: 0,
    position: 'relative',
  },
};

const TileBoard = ({
  cards,
  board,
  config,
  viewConfig,
  classes,
  onCardClick,
  width,
  onChange,
  onUpdateCard,
  onSetScrollPos,
  scrollPos,
}) => {
  const innerTableWrapperRef = useRef();
  const outerTableWrapperRef = useRef();

  useEffect(() => {
    const handleScroll = event => {
      const { xColumns } = config;
      const lastScrollX = Math.max(0, event.target.scrollLeft);
      if (event.target.scrollTop === 0) {
        window.requestAnimationFrame(() => {
          for (let i = 0; i < xColumns.length; i++) {
            document.getElementById(`col-${i}`).style.left = `${lastScrollX}px`;
          }
        });
      }
    };

    window.addEventListener('scroll', handleScroll, true);

    if (scrollPos.scrollY && scrollPos.scrollY > 0) {
      innerTableWrapperRef.current.scrollTop = scrollPos.scrollY;
    }

    if (scrollPos.scrollX && scrollPos.scrollX > 0) {
      outerTableWrapperRef.current.scrollLeft = scrollPos.scrollX;
    }

    return () => {
      const scrollTop = innerTableWrapperRef?.current?.scrollTop;
      const scrollLeft = outerTableWrapperRef?.current?.scrollLeft;
      onSetScrollPos({
        pos: { scrollY: scrollTop, scrollX: scrollLeft },
      });
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [config, onSetScrollPos, scrollPos]);

  const handleUpdateCard = ({ card, rowIndex, columnIndex, sourceColumnIndex }) => {
    onUpdateCard({
      card,
      values: {
        from: config.xColumns[rowIndex],
        to: calculateToValue({
          board,
          card,
          oldValue: config.yColumns[sourceColumnIndex],
          newValue: config.yColumns[columnIndex],
        }),
      },
    });
  };

  const handleClick = id => {
    const expanded = viewConfig.expandedTileRows.indexOf(id) > -1;

    if (expanded) {
      onChange(
        viewConfig.set(
          'expandedTileRows',
          viewConfig.expandedTileRows.filter(item => item !== id),
        ),
      );
    } else {
      onChange(viewConfig.set('expandedTileRows', viewConfig.expandedTileRows.concat(id)));
    }
  };

  const handleExpandCell = ({ key }) => {
    onChange(viewConfig.set('expandedTileCells', viewConfig.expandedTileCells.concat(key)));
  };

  const handleCollapseCell = ({ key }) => {
    onChange(
      viewConfig.set(
        'expandedTileCells',
        viewConfig.expandedTileCells.filter(item => item !== key),
      ),
    );
  };

  // The user who is listed in the FROM group in the question
  // must be able to move on any cell in the FROM group row
  // if he belongs to this group
  const canFromMemberDropOnCell = (fieldConfig, card) => {
    const { initial_step_id } = board.step_config;
    const fromFieldValue = find(card.fields, {
      id: fieldConfig.from_member_field,
    }).value[0];

    const isAuthorityContainsFrom = !!find(fieldConfig.allowed_from_authorities, {
      id: fromFieldValue.id,
    });

    // there is no need to check if the user has permission to move to the initial step
    // if the card is already in that step. It will be an update operation
    if (card.step.id === initial_step_id) {
      return isAuthorityContainsFrom;
    }

    return isAuthorityContainsFrom && card.permissions.MOVE[initial_step_id];
  };

  // The user who is listed in the TO group in the question
  // must be able to move question only in the cell with
  // appropriate FROM and TO values (without changing them)
  const canDropOnFromToCell = (card, rowIndex, columnIndex) => {
    const initialStepId = board.step_config.initial_step_id;
    const boardFields = getBoardFields({ board, card });
    const sameFrom = !!find(boardFields.from.value, {
      id: config.xColumns[rowIndex].id,
    });

    const sameTo = !!find(boardFields.to.value, {
      id: config.yColumns[columnIndex].id,
    });

    return sameFrom && sameTo && card.permissions.MOVE[initialStepId];
  };

  // In the case when "to" field is multiple, the user should be able to move
  // the card only to cell with "to", which is contained in the card "to" value
  // (should not update "to" values)
  const canDropOnCellDependingOnTo = ({ stepConfig, fieldConfig, card, toGroupId }) => {
    const initialStepId = stepConfig.initial_step_id;
    const toFieldConfig = fieldConfig.fields.find(
      field => field.id === fieldConfig.to_member_field,
    );
    const toField = card.fields.find(field => field.id === fieldConfig.to_member_field);

    if (card.step.id !== initialStepId && toFieldConfig.multiple) {
      return !!find(toField.value, { id: toGroupId });
    }

    return true;
  };

  const canDropOnCell = ({ card, rowIndex, columnIndex }) => {
    const initialStepId = board.step_config.initial_step_id;
    const hasAllowedAuthority = !!find(board.field_config.allowed_from_authorities, {
      id: config.xColumns[rowIndex].id,
    });

    const canDropOnCell = canDropOnCellDependingOnTo({
      stepConfig: board.step_config,
      fieldConfig: board.field_config,
      card,
      toGroupId: config.yColumns[columnIndex].id,
    });

    if (!canDropOnCell) {
      return false;
    }

    if (
      hasAllowedAuthority &&
      board.permissions.FULL_CONTROL &&
      card.permissions.MOVE[initialStepId]
    ) {
      return true;
    }

    const canFromMemberDropOnCellAction = canFromMemberDropOnCell(board.field_config, card);

    if (hasAllowedAuthority && canFromMemberDropOnCellAction) {
      return true;
    }

    return canDropOnFromToCell(card, rowIndex, columnIndex);
  };

  /**
   * From the board config retrieve which cards should be shown in the cell.
   * If a card is to be shown in the cell, both "FROM" (X) and "TO" (Y) has to match on
   * the corresponding entry field.
   * @param  {object} x Discipline of X axis
   * @param  {object} y Discipline of Y axis
   * @return {array}   array of cards
   */
  const getCellData = (x, y) => {
    const filters = getFilters({ viewConfig, board, type: QUESTION });
    return cards
      .filter(card => {
        const boardFields = getBoardFields({ board, card });
        const isCorrectFrom = boardFields.from.value[0].id === x.id;
        const to = boardFields.to.value;
        let isCorrectTo = to.find(discipline => discipline.id === y.id) || false;
        if (!isCorrectTo && to.length === 0) {
          isCorrectTo = isUnassignedColumn({ column: y });
        }
        const filterResults = filters.map(filter =>
          filter.filterFn({
            fieldId: filter.fieldId,
            filterValues: viewConfig.field_filters[QUESTION][filter.fieldId],
            card,
          }),
        );
        return (
          viewConfig.stepIdsToShowOnBoard.indexOf(card.step.id) !== -1 &&
          isCorrectFrom &&
          isCorrectTo &&
          filterResults &&
          filterResults.indexOf(true) < 0
        );
      })
      .sort(cardSorter(viewConfig['questionTitle']));
  };

  const renderHeader = ({ columns, viewConfig, classes, columnWidth }) => (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map(column => (
            <TableCell
              key={`column-${column.name}`}
              classes={{
                head: classes.cellRoot,
              }}>
              <div
                className={classNames(
                  viewConfig.highReadability
                    ? classes.headerColumnHighReadability
                    : classes.headerColumn,
                  classes.truncate,
                )}
                style={{ width: columnWidth }}>
                {column.name}
              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </Table>
  );

  const renderRow = ({
    board,
    xIndex,
    expanded,
    column,
    classes,
    config,
    viewConfig,
    onCardClick,
  }) => (
    <React.Fragment key={`row-${xIndex}`}>
      <ListItem
        classes={{
          root: viewConfig.highReadability ? classes.itemHeaderHighReadability : classes.itemHeader,
          selected: classes.selected,
        }}
        className={classNames(!expanded && classes.itemHeaderCollapsed)}
        style={{
          width:
            config.yColumns.length *
            (viewConfig.highReadability ? HIGH_READABILITY_CELL_WIDTH : DEFAULT_CELL_WIDTH),
        }}
        onClick={() => handleClick(column.id)}>
        {expanded ? (
          <ExpandLess
            className={viewConfig.highReadability ? classes.iconHighReadability : classes.icon}
          />
        ) : (
          <ExpandMore
            className={viewConfig.highReadability ? classes.iconHighReadability : classes.icon}
          />
        )}
        <ListItemText
          id={`col-${xIndex}`}
          classes={{
            root: classes.rootHeader,
            primary: viewConfig.highReadability ? classes.headerHighReadability : classes.header,
          }}
          inset
          primary={column.name}
          className={classes.yColumnHeader}
        />
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {config.yColumns.map((yColumn, yIndex) => (
            <Cell
              canDrop={canDropOnCell}
              rowIndex={xIndex}
              columnIndex={yIndex}
              key={`cell-${xIndex}-${yIndex}`}
              expanded={viewConfig.expandedTileCells.indexOf(`${xIndex}-${yIndex}`) > -1}
              onExpand={handleExpandCell}
              onCollapse={handleCollapseCell}
              type={QUESTION}
              data={getCellData(column, yColumn)}
              onCardClick={onCardClick}
              onUpdateCard={handleUpdateCard}
              board={board}
              viewConfig={viewConfig}
            />
          ))}
        </div>
      </Collapse>
    </React.Fragment>
  );

  const renderTable = ({ board, classes, config, viewConfig, onCardClick }) => (
    <div
      ref={innerTableWrapperRef}
      style={{
        height: window.innerHeight - 220,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}>
      {config.xColumns.map((column, xIndex) => {
        return renderRow({
          board,
          xIndex,
          expanded: viewConfig.expandedTileRows.indexOf(column.id) > -1,
          column,
          classes,
          config,
          viewConfig,
          onCardClick,
        });
      })}
    </div>
  );

  const calculateHeaderWidth = ({ fields, viewConfig }) => {
    if (viewConfig.highReadability) {
      return calculateCellWidth({
        areCellsExpanded: viewConfig.expandedTileCells.length === 0,
        fields,
        minimumCellWidth: HIGH_READABILITY_MINIMUM_CELL_WIDTH,
        defaultWidth: HIGH_READABILITY_CELL_WIDTH,
        titleId: viewConfig[QUESTION_TITLE] || getEntryTitle(fields),
      });
    }

    return calculateCellWidth({
      areCellsExpanded: viewConfig.expandedTileCells.length === 0,
      fields,
      minimumCellWidth: MINIMUM_CELL_WIDTH,
      defaultWidth: DEFAULT_CELL_WIDTH,
      titleId: viewConfig[QUESTION_TITLE] || getEntryTitle(fields),
    });
  };

  const columnWidth = calculateHeaderWidth({
    fields: board.field_config.fields,
    viewConfig,
  });

  return (
    <div
      ref={outerTableWrapperRef}
      style={{
        width,
        overflowX: 'scroll',
        WebkitOverflowScrolling: 'touch',
      }}>
      <div style={{ width: config.yColumns.length * columnWidth }}>
        {renderHeader({
          columns: config.yColumns,
          classes,
          viewConfig,
          columnWidth,
        })}
        {renderTable({
          board,
          classes,
          config,
          viewConfig,
          onCardClick,
        })}
      </div>
    </div>
  );
};

TileBoard.propTypes = {
  cards: PropTypes.array.isRequired,
  board: PropTypes.shape({
    field_config: PropTypes.object,
    step_config: PropTypes.object,
    permissions: PropTypes.shape({
      FULL_CONTROL: PropTypes.bool,
    }),
  }).isRequired,
  config: PropTypes.object.isRequired,
  viewConfig: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onCardClick: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onUpdateCard: PropTypes.func.isRequired,
  onSetScrollPos: PropTypes.func.isRequired,
  scrollPos: PropTypes.shape({
    scrollX: PropTypes.number,
    scrollY: PropTypes.number,
  }).isRequired,
};

export default withStyles(styles)(TileBoard);
