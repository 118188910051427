import React, { useState } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import StopClickPropagation from '../../common/StopClickPropagation';
import AttachmentsMobile from './AttachmentsMobile';
import AttachmentsDesktop from './AttachmentsDesktop';
import DeleteAttachmentsDialog from '../../common/DeleteAttachmentsDialog';

import FilePicker from './FilePicker';

const styles = theme => ({
  attachments: {
    marginTop: 20,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  column: {
    flexBasis: '33.33%',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  badge: {
    top: '-4px',
    right: '-30px',
    transform: 'scale(1)',
  },
  flexGrow: {
    flex: '1 1 auto',
  },
  uploadInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    opacity: 0,
  },
});

const filePickerStyles = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 200,
  },
  content: {
    width: 500,
    height: 400,
    position: 'static',
  },
};

const buttonStyles = {
  backgroundColor: '#13627c',
  color: '#fff',
  marginTop: 20,
  marginLeft: 5,
};

const cancelButtonStyles = {
  marginTop: 20,
  marginLeft: '70%',
};

const Attachments = props => {
  const {
    activeCommunity,
    activeRoom,
    classes,
    item,
    attachments,
    members,
    isUploading,
    isDeleting,
    onUploadAttachment,
    onDeleteAttachment,
    onDownloadAttachment,
    onAddLinks,
  } = props;

  const [deletingRow, setDeletingRow] = useState({});
  const [openDeleteAttachmentsDialog, setOpenDeleteAttachmentsDialog] = useState(false);
  const [openFilePicker, setOpenFilePicker] = useState(false);

  const handleUploadAttachment = e => {
    const files = Array.isArray(e) ? e : e.target.files;
    onUploadAttachment({ files });
  };

  const commitChanges = ({ deleted }) => {
    setDeletingRow(attachments[deleted[0]]);
    setOpenDeleteAttachmentsDialog(true);
  };

  const cancelDelete = () => {
    setOpenDeleteAttachmentsDialog(false);
    setDeletingRow({});
  };

  const deleteAttachment = () => {
    onDeleteAttachment({
      attachmentId: deletingRow.id,
    });
    setOpenDeleteAttachmentsDialog(false);
    setDeletingRow({});
  };

  const downloadAttachment = attachment => {
    onDownloadAttachment({ attachment });
  };

  const formatAttachmentLink = ({ row }) => (
    <Tooltip title={intl.get('common.attachments.download')}>
      <span className={classes.link} onClick={e => downloadAttachment(row)}>
        {row.destination_version ? `${row.name} (${row.destination_version})` : row.name}
      </span>
    </Tooltip>
  );

  const handleAddLinks = ({ files }) => {
    const links = files.map(file => ({
      destination: file.id,
      destination_version: file.version,
      force: true,
      name: file.name,
      path: file.path,
      type: 'internal-link',
    }));
    onAddLinks(links);
    handleCloseFilePicker();
  };

  const handleOpenFilePicker = () => setOpenFilePicker(true);

  const handleCloseFilePicker = () => setOpenFilePicker(false);

  return (
    <ExpansionPanel className={classes.attachments} disabled={!item.id} defaultExpanded={!!item.id}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <div style={{ paddingRight: 40, display: 'flex', width: '100%' }}>
          <div className={classes.column}>
            <Badge
              classes={{ badge: classes.badge }}
              color="primary"
              badgeContent={attachments.length}>
              <Typography className={classes.heading}>{intl.get('common.attachments')}</Typography>
            </Badge>
          </div>
          <div className={classes.flexGrow} />
          <StopClickPropagation
            style={{
              position: 'relative',
              paddingRight: 0,
              cursor: !item.permissions.ADD_ATTACHMENT ? 'default' : 'pointer',
            }}>
            <Tooltip title={intl.get('common.link.add')}>
              <Button
                id={'add-link'}
                variant="contained"
                color="primary"
                component="label"
                style={{ marginRight: 20 }}
                onClick={handleOpenFilePicker}
                disabled={!item.permissions.ADD_ATTACHMENT}>
                {intl.get('common.link.add')}
              </Button>
            </Tooltip>
            <Tooltip title={intl.get('common.attachments.upload.drag_and_drop')}>
              <Button
                id={'add-attachment'}
                variant="contained"
                color="primary"
                component="label"
                disabled={!item.permissions.ADD_ATTACHMENT}>
                {intl.get('common.attachments.upload')}
                <input
                  type="file"
                  multiple
                  className={classes.uploadInput}
                  onChange={handleUploadAttachment}
                />
              </Button>
            </Tooltip>
          </StopClickPropagation>
        </div>
      </ExpansionPanelSummary>
      <Hidden smDown>
        <AttachmentsDesktop
          item={item}
          attachments={attachments}
          members={members}
          isUploading={isUploading}
          isDeleting={isDeleting}
          formatAttachmentLink={formatAttachmentLink}
          commitChanges={commitChanges}
          onUploadAttachment={handleUploadAttachment}
        />
      </Hidden>
      <Hidden mdUp>
        <AttachmentsMobile
          item={item}
          attachments={attachments}
          formatAttachmentLink={formatAttachmentLink}
          commitChanges={commitChanges}
        />
      </Hidden>
      <Divider />
      <DeleteAttachmentsDialog
        open={openDeleteAttachmentsDialog}
        record={deletingRow}
        onClose={cancelDelete}
        onDelete={deleteAttachment}
      />
      {openFilePicker && (
        <FilePicker
          lazyInit={true}
          styles={filePickerStyles}
          buttonStyles={buttonStyles}
          buttonText={intl.get('common.link.add.title')}
          cancelButtonStyles={cancelButtonStyles}
          cancelButtonText={intl.get('common.dialog.cancel')}
          restrictToCommunity={activeCommunity}
          defaultRoom={activeRoom}
          callback={handleAddLinks}
        />
      )}
    </ExpansionPanel>
  );
};

Attachments.propTypes = {
  activeCommunity: PropTypes.string.isRequired,
  activeRoom: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    link: PropTypes.string.isRequired,
    uploadInput: PropTypes.string.isRequired,
    attachments: PropTypes.string.isRequired,
    flexGrow: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    badge: PropTypes.string.isRequired,
    column: PropTypes.string.isRequired,
  }).isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  isUploading: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.string,
    permissions: PropTypes.shape({
      ADD_ATTACHMENT: PropTypes.bool,
    }),
  }).isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({})),
  isDeleting: PropTypes.bool,
  onUploadAttachment: PropTypes.func,
  onDeleteAttachment: PropTypes.func,
  onDownloadAttachment: PropTypes.func,
  onAddLinks: PropTypes.func,
};

Attachments.defaultProps = {
  attachments: [],
  isUploading: false,
  isDeleting: false,
  onUploadAttachment: () => {},
  onDeleteAttachment: () => {},
  onDownloadAttachment: () => {},
  onAddLinks: () => {},
};

export default withStyles(styles)(Attachments);
