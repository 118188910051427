import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import IconInfo from '@material-ui/icons/InfoOutlined';
import IconEdit from '@material-ui/icons/EditOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useHistory, useParams } from 'react-router-dom';

import * as deliveryActions from '../../thunks/Deliveries';
import { fetchRoomMembers } from '../../thunks/Boards';
import ListBoard from './ListBoard';
import {
  actions as dActions,
  selectDeliveries,
  selectIsLoadingDeliveries,
  selectLastActiveDelivery,
  selectListViewConfig,
} from '../../ducks/Deliveries';
import { selectActiveBoard, selectRoomMembers } from '../../ducks/Boards';
import { actions as boardActions } from '../../ducks/Boards';
import { LIST_DELIVERY_VIEW_MODE } from '../../utils/ViewModeUtil';
import {
  deliveryFieldsToColumns,
  generateNumberOfCardsMessage,
  STATUS_COLUMN,
} from '../../utils/ListViewUtil';
import PageWrapper from '../../common/PageWrapper';
import {
  toPrimitiveFieldValue,
  itemToRow,
  getCellComponentRenderer,
  getCardInfo,
  getEntryTitle,
} from '../../utils/FieldUtil';
import { canEditDelivery } from '../../utils/PermissionUtils';
import { Cell } from '../../common/table';
import ToggleIconButton from '../../common/ToggleIconButton';
import { alertAction } from '../../thunks/Alerts';
import DeleteDeliveryDialog from '../../common/DeleteCardItemDialog';
import Error from '../../common/Error';
import DeleteIconButton from '../../common/DeleteIconButton';
import PropTypes from 'prop-types';
import SubscriptionContainer from '../../common/SubscriptionContainer';
import { exportContent } from '../../services/Export';
import saveAs from 'file-saver';
import { generateExportRequest } from '../../utils/ExportUtil';

let searchTerm = '';

const mapStateToProps = () =>
  createStructuredSelector({
    activeBoard: selectActiveBoard(),
    isLoadingDeliveries: selectIsLoadingDeliveries(),
    deliveries: selectDeliveries(),
    roomMembers: selectRoomMembers(),
    lastActiveDelivery: selectLastActiveDelivery(),
    listViewConfig: selectListViewConfig(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...deliveryActions,
      ...boardActions,
      ...dActions,
      cleanUp: dActions.cleanUp,
      fetchRoomMembers,
    },
    dispatch,
  ),
});

const ListDeliveryContainer = ({
  activeBoard,
  deliveries,
  lastActiveDelivery,
  isLoadingDeliveries,
  roomMembers,
  listViewConfig: initialViewConfig,
  actions,
}) => {
  const history = useHistory();
  const { boardId } = useParams();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [filters, setFilters] = useState([]);
  const [viewConfig, setViewConfig] = useState(initialViewConfig);
  const [deliveryToDelete, setDeliveryToDelete] = useState(null);

  useEffect(() => {
    actions.fetchDeliveries({ boardId });
    if (roomMembers.length < 1 || activeBoard.id.toString() !== boardId) {
      actions.fetchRoomMembers({ boardId });
    }

    searchTerm = localStorage.getItem('searchTerm') || '';
    setFilters(JSON.parse(localStorage.getItem('filters')) || []);
  }, [boardId, activeBoard.id, roomMembers.length, actions]);

  useEffect(() => {
    return () => {
      if (viewConfig) {
        actions.setListViewConfig(viewConfig);
      }
    };
  }, [viewConfig, actions]);

  const handleSortChange = sorter => {
    setViewConfig(prev => ({ ...prev, sorter }));
  };

  const handleColumnOrderChange = columnOrder => {
    setViewConfig(prev => ({ ...prev, columnOrder }));
  };

  const handleColumnVisibilityChange = visibleColumns => {
    setViewConfig(prev => ({ ...prev, visibleColumns }));
  };

  const handleSearch = searchTerm => {
    localStorage.setItem('searchTerm', searchTerm);
  };

  const handleFilter = filters => {
    localStorage.setItem('filters', JSON.stringify(filters));
    setFilters(filters);
  };

  const handleDeliveryEditClick = deliveryId => {
    actions.setLastActiveDelivery({
      lastActiveDelivery: deliveryId,
    });
    history.push(`/${boardId}/deliveries/${deliveryId}?view=${LIST_DELIVERY_VIEW_MODE}`);
  };

  const handleDeliveryCreateClick = () =>
    history.push(`/${boardId}/create-delivery?view=${LIST_DELIVERY_VIEW_MODE}`);

  const handleDelete = () => {
    setOpenDeleteDialog(false);

    actions
      .deleteDelivery({
        boardId: activeBoard.id,
        delivery: deliveryToDelete,
      })
      .then(action =>
        alertAction({
          action,
          success: intl.get('board.tooltips.delivery.delete.success.message'),
          error: intl.get('board.tooltips.delivery.delete.error.message'),
          onSuccess: () => {},
        }),
      );
  };

  const calculateStatus = (row, fieldId) =>
    row.fields.find(field => field.id === fieldId && field.value)
      ? intl.get('delivery.status.delivered')
      : intl.get('delivery.status.not_delivered');

  const rowToDeliveryInfo = row => {
    const cardInfo = row.fields.reduce((deliveryInfo, field) => {
      deliveryInfo[field.id] = toPrimitiveFieldValue(field);
      return deliveryInfo;
    }, {});
    return cardInfo.merge({
      [STATUS_COLUMN]: row[STATUS_COLUMN],
      ...getCardInfo(row, roomMembers),
    });
  };

  const deliveriesToRows = (deliveries, deliveryConfig) =>
    deliveries.asMutable().map(delivery => {
      let row = itemToRow({ item: delivery, fieldConfig: deliveryConfig });
      row[STATUS_COLUMN] = calculateStatus(delivery, deliveryConfig.delivery_met_date_field);
      return row;
    });

  const handleClickOpenDialog = delivery => {
    setDeliveryToDelete(delivery);
    setOpenDeleteDialog(true);
  };

  const renderDeliveryLinkButton = ({ row }) => {
    const canEdit = canEditDelivery(row.permissions);

    return (
      <Cell>
        <ToggleIconButton
          tooltipText={intl.get(
            canEdit
              ? 'board.views.list.tooltips.edit_delivery'
              : 'board.views.list.tooltips.view_delivery',
          )}
          generalIcon={canEdit ? <IconEdit /> : <IconInfo />}
          hoverIcon={canEdit ? <IconEdit /> : <IconInfo />}
          disabled={false}
          onClick={() => handleDeliveryEditClick(row.id)}
        />
        <DeleteIconButton
          tooltipText={intl.get('board.tooltips.delivery.delete.question.title')}
          onClick={() => handleClickOpenDialog(row)}
          disabled={!row.permissions.DELETE}
        />
      </Cell>
    );
  };

  const handleExportClick =
    ({ folderName }) =>
    ({ columns, items }) => {
      const exportRequest = generateExportRequest({ columns, items });

      return exportContent({
        boardId: activeBoard.id,
        exportRequest,
      }).then(response => Promise.resolve(saveAs(response.data, `${folderName}.csv`)));
    };

  if (isLoadingDeliveries) {
    return <LinearProgress />;
  }

  return (
    <PageWrapper title={intl.get('app_bar.delivery_list_view')}>
      {activeBoard.delivery_config ? (
        <React.Fragment>
          <ListBoard
            lastActiveItem={lastActiveDelivery}
            board={activeBoard}
            items={deliveriesToRows(deliveries, activeBoard.delivery_config)}
            height={window.innerHeight - 192}
            style={{ marginBottom: 15 }}
            columns={deliveryFieldsToColumns(activeBoard.delivery_config)}
            renderCellComponent={getCellComponentRenderer(
              activeBoard.delivery_config.fields,
              roomMembers,
            )}
            editing={{
              renderLinkButtons: renderDeliveryLinkButton,
              width: 110,
            }}
            rowToInfo={rowToDeliveryInfo}
            readOnly={!activeBoard.delivery_config.permissions.ADD_DELIVERY}
            onItemCreateClick={handleDeliveryCreateClick}
            tooltips={{
              create: intl.get('board.tooltips.add_delivery'),
            }}
            subscription={
              <SubscriptionContainer id={activeBoard.delivery_config.delivery_folder_id} />
            }
            filters={filters}
            onFiltersChange={handleFilter}
            onSearch={handleSearch}
            onSortChange={handleSortChange}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            searchTerm={searchTerm}
            selection={{
              enableSelection: true,
              helperText: intl.get('board.views.list.tooltips.cards.export.helper_text'),
              numberOfItemsMessage: generateNumberOfCardsMessage,
            }}
            sorter={viewConfig.sorter}
            defaultSorter={[{ columnName: getEntryTitle(activeBoard.delivery_config.fields) }]}
            columnOrder={viewConfig.columnOrder}
            visibleColumns={viewConfig.visibleColumns}
            onExportClick={handleExportClick({
              folderName: activeBoard.delivery_config.name,
            })}
          />
          <DeleteDeliveryDialog
            open={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
            onDelete={handleDelete}
            titles={{
              deleteDialogTitle: intl.get('board.tooltips.delivery.delete.question.title'),
              deleteDialogMessage: intl.get('board.tooltips.delivery.delete.question.message'),
            }}
          />
        </React.Fragment>
      ) : (
        <Error text={intl.get('delivery.not_found')} />
      )}
    </PageWrapper>
  );
};

ListDeliveryContainer.propTypes = {
  activeBoard: PropTypes.shape({
    id: PropTypes.string,
    delivery_config: PropTypes.shape({
      name: PropTypes.string,
      delivery_folder_id: PropTypes.string,
      delivery_met_date_field: PropTypes.string,
      fields: PropTypes.arrayOf(PropTypes.shape({})),
      permissions: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  params: PropTypes.shape({
    boardId: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    deleteDelivery: PropTypes.func.isRequired,
    fetchDeliveries: PropTypes.func.isRequired,
    cleanUp: PropTypes.func.isRequired,
    fetchRoomMembers: PropTypes.func.isRequired,
    setLastActiveDelivery: PropTypes.func.isRequired,
    setListViewConfig: PropTypes.func.isRequired,
  }),
  deliveries: PropTypes.array.isRequired,
  lastActiveDelivery: PropTypes.string,
  isLoadingDeliveries: PropTypes.bool.isRequired,
  roomMembers: PropTypes.arrayOf(PropTypes.shape({})),
  listViewConfig: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      boardId: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

ListDeliveryContainer.defaultProps = {
  roomMembers: [],
  match: {},
  history: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ListDeliveryContainer);
