import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import LinearProgress from '@material-ui/core/LinearProgress';

import { alertAction } from '../../thunks/Alerts';
import { selectActiveBoard, selectIsLoadingActiveBoard } from '../../ducks/Boards';
import { selectIsLoadingCards } from '../../ducks/Cards';
import * as boardsActions from '../../thunks/Boards';
import * as cardsActions from '../../thunks/Cards';
import Error from '../../common/Error';
import PropTypes from 'prop-types';

import { CardCreateContainer as CardCreate, CardUpdateContainer as CardUpdate } from './';
import { actions as roomActions, selectActiveRoom } from '../../ducks/Rooms';
import { actions as communityActions, selectActiveCommunity } from '../../ducks/Communities';

const mapStateToProps = () =>
  createStructuredSelector({
    board: selectActiveBoard(),
    activeRoom: selectActiveRoom(),
    activeCommunity: selectActiveCommunity(),
    isLoadingBoard: selectIsLoadingActiveBoard(),
    isLoadingCards: selectIsLoadingCards(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...cardsActions,
      ...boardsActions,
      setActiveRoom: roomActions.setActiveRoom,
      setActiveCommunity: communityActions.setActiveCommunity,
    },
    dispatch,
  ),
});

const CardContainer = ({
  board,
  activeCommunity,
  activeRoom,
  actions,
  match,
  isLoadingBoard,
  isLoadingCards,
}) => {
  useEffect(() => {
    const { boardId } = match.params;
    // Direct link, assume nothing is loaded.
    if (!board) {
      actions.fetchActiveBoard({ boardId }).then(action => {
        const workflowConfig = action.payload.activeBoard.workflow_config;

        if (activeCommunity !== workflowConfig.community_id) {
          actions.setActiveCommunity({
            communityId: workflowConfig.community_id,
          });
        }
        if (activeRoom !== workflowConfig.room_id) {
          actions.setActiveRoom({ roomId: workflowConfig.room_id });
        }
        alertAction({ action, error: intl.get('card.fetch_board.error') });
      });

      actions
        .fetchCards({ boardId })
        .then(action => alertAction({ action, error: intl.get('card.fetch.error') }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board, activeCommunity, activeRoom, actions, match.params]);

  // Still loading active board
  if (isLoadingBoard || isLoadingCards) {
    return <LinearProgress />;
  }

  // Board fetch failed
  if (!board) {
    return <Error text={intl.get('board.not_found')} />;
  }

  if (match.params.cardId) {
    return <CardUpdate board={board} params={match.params} />;
  }

  return <CardCreate board={board} params={match.params} />;
};

CardContainer.propTypes = {
  board: PropTypes.shape({}),
  isLoadingBoard: PropTypes.bool.isRequired,
  isLoadingCards: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    fetchActiveBoard: PropTypes.func,
    fetchCards: PropTypes.func,
    setActiveCommunity: PropTypes.func,
    setActiveRoom: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      boardId: PropTypes.string,
      cardId: PropTypes.string,
    }),
  }),
  activeCommunity: PropTypes.string,
  activeRoom: PropTypes.string,
};

CardContainer.defaultProps = {
  board: {},
  match: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(CardContainer);
